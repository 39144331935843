export default {
  namespaced: true,
  state: {
    onlineUser: [],
  },
  mutations: {
    setOnlineUser(state, data) {
      state.onlineUser = data;
    },
  },
  actions: {},
};
