<template>
  <div class="soar-app-logo">
    <div class="img-container">
      <img src="../../assets/images/gateway/app-icon1.png" alt="logo"/>
      <div class="title" v-show="!$store.state.layout.visibleMenu">智慧公园管理系统</div>
    </div>
  </div>
</template>

<script >
export default {
  name:"layout-logo",
}
</script>

<style>
.logo-title-enter-active,.logo-title-leave-active {
  transition: all 2s linear;
}
.logo-title-leave{
  width: 158px;
}

.logo-title-leave-to{
  width: 0;
}

.logo-title-enter-from{
  width: 0;
}
.logo-title-enter-to{
  width: 158px;
}
</style>

<style scoped lang="less">
.soar-app-logo{
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease;
  height: 48px;
  justify-content: flex-start;
  .img-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .title{
      position: absolute;
      left: 50px;
      top: 5px;
    }
    img{
      width: 32px;
      height: 32px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .title{
    font-size: 16px;
    font-weight: 700;
    transition: all .5s linear;
    line-height: normal;
    user-select: none;
    width:158px;
    color: var(--soar-menu-title-color);
    position: absolute;
    .ellipsis(1);
  }
}
</style>
