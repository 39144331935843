<template>
  <div>
    <Floder ref="floder" />
    <his-assist-task />
    <!-- <i-rich-talk /> -->
    <shuguo-talk v-if="showTalk" />
    <div v-if="!loading">
      <router-view></router-view>
    </div>
    <div v-else  class="page-loading-start">
      <div class="page-loading-container">
        <div class="page-loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="page-loading-text">加载中...</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Floder from "@/components/floder/src";
import HisAssistTask from '@/views/home/his-assist-task'
import { EventsEnum } from '@/services/eventBus'
import loadAmap from '@/components/amap/loadAmap'
// import IRichTalk from '@/components/i-rich-talk/index.vue'
import shuguoTalk from '@/components/shuguo-talk/index.vue'
import { ShuGuoTalkEnum } from '@/services/ShuGuoTalkEnum'
export default {
  name: "vue-webadmin",
  components: { shuguoTalk, HisAssistTask, Floder},
  data(){
    return {
      loading:true,
      showTalk:false
    }
  },
  methods:{
    async init(){
      if(this.$store.state.menu.menus){
        Vue.prototype.$eventBus.$emit(EventsEnum.TASK_HIST_ASSIST_TASK)
      }
      await this.$store.dispatch("taskSocket/init");
      await loadAmap()
      this.loading = false
    },
    toggleShowTalk(){
      this.showTalk = true
    }
  },
  mounted () {
    this.$eventBus.$on(ShuGuoTalkEnum.INIT,this.toggleShowTalk)
    if(window.sessionStorage.getItem('token')){
      this.showTalk = true
    }
    Vue.prototype.$floder = this.$refs.floder
    this.init()
  }
}
</script>

<style>
.dg.ac{
  z-index: 66843 !important;
}

.amap-marker-label {
  border: none;
  background: transparent;
  /* position: relative; */
}
</style>

<style scoped lang="less">
.page-loading-container{
  text-align: center;
}
.page-loading-start {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #0c2135;
  flex-wrap: wrap;
}

.page-loading-text {
  width: 100vw;
  font-style: italic;
  font-size: 40px;
  text-indent: 10px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}

.page-loading-start .page-loading {
  width: 180px;
  height: 40px;
  margin: 100px auto 0;
}

.page-loading > span {
  display: inline-block;
  width: 8px;
  height: 100%;
  border-radius: 4px;
  margin-right: 10px;
  background: #90eeeb;
  -webkit-animation: pageLoading 1.04s ease infinite;
}

@-webkit-keyframes pageLoading {
  0%, 100% {
    height: 40px;
    background: #90eeeb;
  }
  50% {
    height: 60px;
    margin-top: -20px;
    background: #ade6c1;
  }
}

.page-loading span:nth-child(2) {
  -webkit-animation-delay: 0.13s;
}

.page-loading span:nth-child(3) {
  -webkit-animation-delay: 0.26s;
}

.page-loading span:nth-child(4) {
  -webkit-animation-delay: 0.39s;
}

.page-loading span:nth-child(5) {
  -webkit-animation-delay: 0.52s;
}
</style>
