<template>
  <div class="layout-container">
    <home-header />
    <div class="view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/views/home/header";
export default {
  name: "layout-full-screen",
  components: { HomeHeader },
};
</script>

<style scoped lang="less">
.layout-container {
  height: 100vh;
  width: 100vw;
  position: relative;
  .view {
    height: 100vh;
    width: 100vw;
  }
}
</style>
