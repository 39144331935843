
import CoordTransform from './CoordTransForm';

class AmapMercatorTilingScheme extends Cesium.WebMercatorTilingScheme {
    _projection = {};

    constructor() {
        super();

        let projection = new Cesium.WebMercatorProjection();

        this._projection.project = function (cartographic, result) {
            if(isNaN(cartographic.longitude)||isNaN(cartographic.latitude)){
                console.error('project',cartographic.longitude,cartographic.latitude)
            }
            result = CoordTransform.WGS84ToGCJ02(
              Cesium.Math.toDegrees(cartographic.longitude),
              Cesium.Math.toDegrees(cartographic.latitude)
            );
            result = projection.project(new Cesium.Cartographic(Cesium.Math.toRadians(result[0]), Cesium.Math.toRadians(result[1])));
            return new Cesium.Cartesian2(result.x, result.y);
        };

        this._projection.unproject = function (cartesian, result) {
            let cartographic = projection.unproject(cartesian);
            if(isNaN(cartographic.longitude)||isNaN(cartographic.latitude)){
                console.log('unproject',cartographic.longitude,cartographic.latitude)
            }
            result = CoordTransform.GCJ02ToWGS84(
              Cesium.Math.toDegrees(cartographic.longitude),
              Cesium.Math.toDegrees(cartographic.latitude)
            );
            return new Cesium.Cartographic(Cesium.Math.toRadians(result[0]), Cesium.Math.toRadians(result[1]));
        };
    }
}

export default AmapMercatorTilingScheme;
