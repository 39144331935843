import http from '@/libs/http'
import Qs from 'qs'
import router from '@/router'
import store from "@/store"
// 登录
export function login(data) {
  return http.post('/login',
    Qs.stringify(data),
  {
    headers:{
      'Content-Type':'application/x-www-form-urlencoded'
    }
  }
)
}

// 登出
export function logout(url){
  router.replace(url ?? '/login')
  function after(r){
    window.sessionStorage.clear();
    store.commit("menu/setMenus",null)
    window.location.reload();
    return r
  }
  return http.post('/logout').then(after).catch(err=>{
    after(err)
    throw new Error(err)
  })
}

// 获取验证码
export function getCaptcha(data) {
  return http.get('/sys-login/getCaptcha', data)
}
