<template>
<el-dialog :visible="visible"
           title="3D设置"
           width="550px"
           :modal="false"
           @close="visible = false"
           z-index="99999"
           destroy-on-close
           class="dialog-bg"
           append-to-body>
  <div ref="datContainer"></div>
</el-dialog>
</template>

<script>
import { CesiumGui } from '@/components/cesium-map/config'

export default {
  name: 'cesium-setting',
  data(){
    return {
      visible:false
    }
  },
  methods:{
    show(){
      this.visible = true
      // console.log(CesiumGui)
      this.$nextTick(()=>{
        this.$refs.datContainer.appendChild(CesiumGui.domElement)
      })
    }
  },
}
</script>

<style scoped lang="less">
.dialog-bg{
  /deep/ .el-dialog{
    background-color: #000;
    border-radius: 4px;
    overflow: hidden;
  }
  /deep/ .el-dialog__title{
    color: #ffffff;
  }
  /deep/ .el-dialog__body{
    padding: 0 0 20px;
  }
  /deep/ .property-name{
    width: 65%;
  }
  /deep/ .c{
    width:35%;
  }
  /deep/ .close-button{
    display: none;
  }
}
</style>
