<template>
<div class="cs-panel" :class="{max:isMax}">
  <div class="cs-panel-header" :id="'head' + headId" >
    <div class="cs-title-box">
      <div class="cs-title">
        <i :class="icon" class="soar-icon"/><span class="cs-title-content">{{ title }}</span>
      </div>
      <img src="@/assets/images/tck.png" alt="tck" class="bg"/>
    </div>
    <span class="cs-panel-button">
      <slot name="button">
        <span v-if="min"><i class="el-icon-minus el-icon--right"  @click="mined"/></span>
        <span v-if="max && isMax"><i class="el-icon-copy-document el-icon--right"  @click="maxed"/></span>
        <span v-if="max && !isMax"><i class="el-icon-full-screen el-icon--right"  @click="maxed"/></span>
        <span v-if="close"><i class="el-icon-close el-icon--right"  @click="closed"/></span>
      </slot>
    </span>
  </div>
  <el-collapse-transition>
    <div class="cs-panel-body"><slot></slot></div>
  </el-collapse-transition>
</div>
</template>
<style lang="less" scoped>
.cs-panel {
  min-width: 180px;
  //background-image: url("/static/images/panel/base.png");
}

.cs-panel-header {
  user-select: none;
  position: relative;
  height: 26px;
  cursor: move;
  &:before{
    display: block;
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 19px;
    z-index: -1;
    background: linear-gradient(90deg,#0c547b 20%,#09274900 100%);
  }
  .cs-title-box{
    display: flex;
    align-items: center;
    .cs-title{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 4px;
      background-image: url("~@/assets/images/tck_1px.png");
      background-repeat: repeat-x;
      background-size: 1px 100% ;
      letter-spacing: 3px;
      .cs-title-content{
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
      }
    }
    .bg{
      height: 26px;
      width: 20px;
      display: block;
    }
  }
  .cs-panel-button {
    position: absolute;
    bottom: -1px;
    right: 0;
    color: @light-char-color;
    .el-icon--right{
      padding: 3px;
      margin-left: 5px;
      font-size: 10px;
      cursor: pointer;
      border: 1px solid #01B2FF;
      background-color: #094b6990;
    }
  }

}

.cs-panel-body {
  pointer-events: all;
  border: 1px solid #01B2ff;
  background: linear-gradient(0deg, #0c547bD2 30%, #092749D2 100%);
}
.max{
  .cs-panel-body {
    height: 100%;
  }
}

/**
 * 图标
 */

.soar-icon{
  display: block;
  margin: 0 7px;
  width: 15px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  //默认
  &.soar-icon-default{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 10px;
  }
  //任务下达图标
  &.soar-icon-task-assignment{
    background-image: url("~@/assets/images/icon_rwxd.png");
  }
  //事件报送图标
  &.soar-icon-task-update{
    background-image: url("~@/assets/images/icon_sjsb.png");
  }
}
</style>
<script>
  export default {
    name: "CsPanel",
    props: {
      title: {
        type: String,
        default: ''
      },
      headId: {
        type: String,
        default: 'panelHeadId'
      },
      icon: {
        type: String,
        default: 'soar-icon-default'
      },
      close: {
        type: Boolean,
        default: true
      },
      min: {
        type: Boolean,
        default: true
      },
      max: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isMax: false
      }
    },
    methods: {
      closed: function () {
        this.$emit('closed');
      },
      mined: function () {
        this.$emit('mined',this.headId,true);
      },
      show: function(){
        this.$emit('mined',this.headId,false);
      },
      maxed: function() {
        this.isMax = !this.isMax;
        this.$emit('maxed',this.headId,this.isMax);
      }
    }
  }
</script>
