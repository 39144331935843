<template>
  <div>
    <!-- 数果对讲api -->
    <div style="position: absolute; left: 0; bottom: 0" v-show="show">
      <!-- style="transform: translate(900px, 350px)" -->
      <div
        v-draggable="dragOptions"
        id="i-dispatch-body"
        class="i-dispatch-container"
        ref="i-dispatch-body"
      >
        <div id="i-dispatch" class="head">
          {{ talkType === "video" ? "视频通话" : "音频通话" }}
        </div>

        <div class="user-info">
          <el-alert
            v-show="talkType === 'audio'"
            :title="callText"
            :closable="false"
            :type="speaker"
          >
          </el-alert>
          <video
            ref="face"
            id="rich-audio"
            autoplay
            style="width: 300px; height: 172px; background-color: #0a0a0a"
            v-show="talkType === 'video'"
          />
          <div class="err-msg" v-show="talkType === 'video'">
            <div class="tool">
              <i
                @click.stop="handleRequestFullScreen"
                class="el-icon-full-screen full-icon"
                title="全屏显示"
              ></i>
            </div>
          </div>
          <img
            :src="avatarUrl || avatarUrlDefault"
            alt="avrer"
            class="user-logo"
            v-show="talkType === 'audio'"
          />
          <div class="name">{{ userInfo.nickName }}</div>
          <div class="position">{{ userInfo.position }}</div>
        </div>

        <!--  通话按钮 -->
        <div class="icon-btn-container">
          <template>
            <!-- 被呼叫 -->
            <template v-if="isComingTalk">
              <!-- 接听按钮 -->
              <img
                src="@/assets/images/call-phone.png"
                alt="hangup"
                class="icon-img2"
                @click="receiveComingAdhocCall"
              />
              <!-- 拒接按钮 -->
              <img
                src="@/assets/images/hangup.png"
                alt="hangup"
                class="icon-img"
                @click="rejectComingAdhocCall"
              />
            </template>
            <!-- 呼叫他人 挂断按钮-->
            <img
              v-else
              src="@/assets/images/hangup.png"
              alt="hangup"
              class="icon-img"
              @click="callEnd"
            />
          </template>
        </div>
        <!-- 话语权 -->
        <div class="icon-btn-container2" v-if="isTalking">
          <span v-if="isMySpeaking">您正在说话...</span>
          <span v-if="!isMySpeaking && isOtherSpeak">对方正在讲话...</span>
          <span v-if="!isMySpeaking && !isOtherSpeak">空闲中</span>
        </div>
        <div class="icon-btn-container2" v-if="isTalking">
          <el-button
            @click="callSpeak"
            v-if="!isMySpeaking"
            :disabled="isOtherSpeak"
            >点击说话</el-button
          >
          <el-button @click="releaseSpeak" v-if="isMySpeaking"
            >点击释放话语权</el-button
          >
        </div>
      </div>

      <audio ref="audio" id="rich-audio" />
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import axios from "axios";
import { mapGetters } from "vuex";
import PCMPlayer from "./js/pcm-player.js";
import accout from "./js/account.js";
import { ShuGuoTalkEnum } from "@/services/ShuGuoTalkEnum";

export default {
  data() {
    return {
      apiBaseUrl: "https://park.hrzhaf.com/sgs-api", //数果api地址
      hostUrl: "wss://sgs.pocyun.com:59300", // websocket地址
      mqttHost: "sgs.pocyun.com",
      mqttPort: 59250,
      userName: accout.userName,
      userNamePaltform: "",
      userPwd: accout.userPwd,
      signKey: "",
      clientId: "6ec49a7abb6e508c", //客户端 ID，固定值
      idNo: "MIUS4czkBBinfAa7", //客户端唯一标识，固定值
      accessToken: "",
      userInfo: {},
      onlineUser: [],
      userTopic: "",
      show: false,
      talkType: "audio",
      callText: "",
      speaker: "success",
      isCaller: false,
      avatarUrl: "",
      avatarUrlDefault: require("@/assets/images/home/avatar.png"),
      dragOptions: {
        recover: false,
        trigger: "#i-dispatch",
        body: "#i-dispatch-body",
      },
      comingTalkData: {},
      isComingTalk: false, //是否被呼叫
      adhocUserId: "", // 被呼叫用户ID
      applyTalkSessionId: "", //会话id
      connectWebsocketAction: null,
      pocTalkAction: null,
      pocAudioPlay: null,
      pocMessageDeal: null,
      pocRecordAction: null,
      pocRecord: null,
      audioRecorderAction: null,
      client: null,
      timer: "",
      timer2: "",
      isTalking: false,
      isMySpeaking: false,
      isOtherSpeak: false,
    };
  },
  computed: {
    ...mapGetters(["sceneAMapPersonnelOnDuty", "scenePersonnelOnDuty"]),
  },
  mounted() {
    this.$refs["i-dispatch-body"].style.transform =
      "translate(" +
      (document.documentElement.clientWidth / 2 - 115) +
      "px, 200px)";
    this.getSignKey();
    // 临时会话
    this.$eventBus.$on(ShuGuoTalkEnum.CALL, this.callAdhocStart);
    // this.getOnlineLocation
    this.$eventBus.$on(ShuGuoTalkEnum.GETONLINE, this.getOnlineLocation);
  },
  beforeDestroy() {
    // 挂断所有会话
    this.closeAllTalk();
    // 删除this.client
    this.client.unsubscribe(this.userTopic);
    this.$eventBus.$off(ShuGuoTalkEnum.CALL, this.callAdhocStart);
    this.$eventBus.$off(ShuGuoTalkEnum.GETONLINE, this.getOnlineLocation);
  },
  methods: {
    // 鉴权
    getSignKey() {
      axios
        .get(this.apiBaseUrl + "/oauth/signKey", {
          params: {
            userName: this.userName,
            clientId: this.clientId,
          },
        })
        .then((response) => {
          this.signKey = response.data.data.signKey;
          this.dealSignKey();
        });
    },
    // 加密signKey
    dealSignKey() {
      let pwd = CryptoJS.MD5(this.userPwd).toString();
      var str =
        "userName=" +
        this.userName +
        "&password=" +
        pwd +
        "&signKey=" +
        this.signKey;
      this.signKey = CryptoJS.MD5(str).toString();
      this.getAccessToken();
    },
    // 获取用户信息、token
    getAccessToken() {
      axios
        .get(this.apiBaseUrl + "/oauth/authorize", {
          params: {
            userName: this.userName,
            clientId: this.clientId,
            sign: this.signKey,
            idNo: this.idNo,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.accessToken = data.accessToken;
          this.userInfo = data;
          this.connectWebsocketAction = this.connectWebsocketActionFun();
          this.initPocTalkAction();
          this.initPocMessageDeal();
          this.getOnlineLocation();
          this.initPocRecordAction();
          this.initAudioRecorderAction();
          this.mqttConnect();
        });
    },
    // 查询所有在线位置
    getOnlineLocation() {
      axios
        .get(this.apiBaseUrl + "/v1/cusmap/location/all", {
          headers: {
            accessToken: this.accessToken,
          },
        })
        .then((response) => {
          this.onlineUser = response.data.data;
          // this.onlineUser[0].presence = "online";
          this.$store.commit("shuguoTalk/setOnlineUser", this.onlineUser);
        });
    },
    handleRequestFullScreen() {},
    // 临时呼叫
    callAdhocStart(data) {
      if (this.show) {
        this.$message("请先结束本次通话");
        return;
      }
      this.avatarUrl = "";
      if (
        this.sceneAMapPersonnelOnDuty &&
        this.sceneAMapPersonnelOnDuty.list &&
        this.sceneAMapPersonnelOnDuty.list.length
      ) {
        let obj = this.sceneAMapPersonnelOnDuty.list.find(
          (el) => el.iDispatchPhone == data.userId
        );
        if (obj) {
          this.avatarUrl = obj.avatar;
        }
      } else {
        if (
          this.scenePersonnelOnDuty &&
          this.scenePersonnelOnDuty.list &&
          this.scenePersonnelOnDuty.list.length
        ) {
          let obj = this.scenePersonnelOnDuty.list.find(
            (el) => el.iDispatchPhone == data.userId
          );
          if (obj) {
            this.avatarUrl = obj.avatar;
          }
        }
      }

      this.isComingTalk = false;
      this.adhocUserId = data.userId.toString();
      let adhocId = Number(parseInt(new Date().getTime() * Math.random() * 100))
        .toString(16)
        .toString(16);
      this.pocTalkAction.adhocCall(adhocId, this.adhocUserId, false);
      this.show = true;
      this.speaker = "warning";
      this.userNamePaltform = data.userName;
      this.callText = `正在发起通话中...`;
    },
    // 加入群组
    joinGroup() {
      this.pocTalkAction.joinGroup(12345);
    },
    // 申请发言
    callSpeak() {
      // alert(this.applyTalkSessionId)
      this.pocTalkAction.doRequestSpeak(this.applyTalkSessionId, 1);
      this.isMySpeaking = true;
    },
    // 释放发言权
    releaseSpeak() {
      this.pocTalkAction.doRequestCloseSpeak(this.applyTalkSessionId);
      this.isMySpeaking = false;
    },
    // 挂断会话
    callEnd() {
      // this.pocTalkAction.doCloseTalkAction(this.applyTalkSessionId);
      this.closeAllTalk();
      this.show = false;
    },
    // 连接websocket
    connectWebsocketActionFun() {
      let that = this;
      let connects = [];
      let talkHeartPacketSessionId;
      function initWebsocket(params) {
        if (!that.pocAudioPlay) {
          that.pocAudioPlay = new PCMPlayer({
            encoding: "16bitInt",
            channels: 1,
            sampleRate: 8000,
            flushingTime: 0,
          });
          console.log("pocAudioPlay-------", that.pocAudioPlay);
        }
        let key = connects.length;
        let host = that.hostUrl;
        connects[key] = new WebSocket(host);
        connects[key].binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据;
        connects[key].onopen = onOpen;
        connects[key].onmessage = onMessage;
        connects[key].onclose = onClose;
        connects[key].onerror = onError;
        connects[key].roomId = params.roomId;
        connects[key].talkType = params.talkType;
        if (params.callee != undefined && params.callee != null) {
          connects[key].callee = params.callee;
        }
        if (params.talkType == "adhoc") {
          connects[key].isComing = params.isComing;
          if (params.isComing) {
            connects[key].joinState = params.joinState;
          }
        }
        return params.roomId;
      }
      function onOpen(event) {
        console.log(connects);
        if (event.target.talkType === "adhoc") {
          if (event.target.isComing) {
            switch (event.target.joinState) {
              case 0: //接入
                that.pocTalkAction.doAcceptAdhocCall(event.target.roomId);
                break;
              case 1: //拒接
                that.pocTalkAction.doRejectAdhocCall(event.target.roomId);
                break;
              case 2: //超时
                break;
              default:
                break;
            }
          } else {
            that.pocTalkAction.doAdhocCall(event.target.roomId);
          }
        } else {
          that.pocTalkAction.doJoinGroup(event.target.roomId);
        }
        console.log("连接成功");
      }
      function onMessage(msg) {
        that.pocMessageDeal.receiveMessage(msg.data, msg.target.roomId);
      }
      function onClose(event) {
        that.isOtherSpeak = false;
        that.isTalking = false;
        that.isMySpeaking = false;
        that.pocMessageDeal.pocConnectCloseDeal(event.target.roomId);
        that.isComingTalk = false;
        that.speaker = "error";
        that.callText = "通话已结束";
        console.log("连接关闭");
        that.closeShowTimer(444);
      }
      function onError() {
        console.log("错误信息");
      }
      function sendMessage(connect, data) {
        connect.send(data);
        console.log("sendmsgdata", data);
      }
      function closeConnect(connect) {
        if (!connect) return;
        doDeleteConnect(connect.roomId);
        connect.close();
      }
      function getConnectByConnectId(roomId) {
        let connect = connects.filter(function (t) {
          return t.roomId == roomId;
        });
        if (connect.length === 0) return false;
        return connect[0];
      }
      function doDeleteConnect(roomId) {
        if (connects.length === 0) return;
        let connect = connects.filter(function (t) {
          return t.roomId != roomId;
        });
        connects = connect;
      }
      function doSendTalkHeartPacket() {
        console.log(talkHeartPacketSessionId);
        if (
          talkHeartPacketSessionId != undefined &&
          talkHeartPacketSessionId != null
        )
          return false;
        talkHeartPacketSessionId = setInterval(function () {
          if (connects.length == 0) {
            talkHeartPacketSessionId = null;
            clearInterval(talkHeartPacketSessionId);
            return false;
          }
          let msg = { type: "heartPacket" };
          console.log(connects);
          for (let i = 0; i < connects.length; i++) {
            if (connects[i].readyState == 1) {
              sendMessage(connects[i], JSON.stringify(msg));
            }
          }
        }, 30000);
      }
      function doDeleteAdhocConnect(roomId) {
        let connect = connects.filter(function (t) {
          return t.roomId != roomId && t.talkType == "adhoc";
        });
        if (connect.length === 0) return;
        for (let i = 0; i < connect.length; i++) {
          closeConnect(connect[i]);
        }
      }
      function doDeleteAllConnect() {
        if (connects.length === 0) return;
        for (let i = 0; i < connects.length; i++) {
          that.pocTalkAction.doExitAdhoc(connects[i].roomId);
        }
        connects = [];
      }

      function doGetAllConnect() {
        return connects;
      }

      return {
        sendMessage: sendMessage,
        initWebsocket: initWebsocket,
        closeConnect: closeConnect,
        getConnectByConnectId: getConnectByConnectId,
        doDeleteAdhocConnect: doDeleteAdhocConnect,
        doSendTalkHeartPacket: doSendTalkHeartPacket,
        doDeleteAllConnect: doDeleteAllConnect,
        doGetAllConnect: doGetAllConnect,
      };
    },
    // 对话api方法
    initPocTalkAction() {
      let that = this;
      this.pocTalkAction = {
        doJoinGroup: function (roomId) {
          //接入群组
          let msg = {
            type: "callGroup",
            gid: roomId,
            uid: that.userInfo.userId,
            pwd: that.accessToken,
          };
          console.log(msg);
          msg = JSON.stringify(msg);
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          that.connectWebsocketAction.sendMessage(connect, msg);
        },
        joinGroup: function (roomId) {
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect) return false;
          let params = { roomId: roomId, talkType: "chat" };
          that.connectWebsocketAction.initWebsocket(params);
        },
        adhocCall: function (roomId, callee, isComing, joinState) {
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect) {
            let msg = { type: "exitAdhoc" };
            that.connectWebsocketAction.sendMessage(
              connect,
              JSON.stringify(msg)
            );
            that.connectWebsocketAction.closeConnect(connect);
          }
          let params = {
            roomId: roomId,
            talkType: "adhoc",
            callee: null,
            isComing: false,
          };
          if (isComing) {
            params.isComing = true;
          }
          if (callee != undefined && callee != null) {
            params.callee = callee;
          }
          if (joinState != undefined && joinState != null) {
            params.joinState = joinState;
          }
          that.connectWebsocketAction.initWebsocket(params);
        },
        doAdhocCall: function (roomId) {
          //发起临时呼叫
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          let msg = {
            type: "callAdhoc",
            callee: connect.callee,
            pwd: that.accessToken,
            caller: that.userInfo.userId,
          };
          // console.log(msg);
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
          that.connectWebsocketAction.doDeleteAdhocConnect(roomId);
        },
        doAcceptAdhocCall: function (roomId) {
          //接听临时呼叫
          let msg = {
            type: "acceptAdhoc",
            pwd: that.accessToken,
            uid: that.userInfo.userId,
            roomId: roomId,
          };
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect.readyState != 1) return;
          // console.log(msg);
          that.isComingTalk = false;
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
        },
        doRejectAdhocCall: function (roomId) {
          //拒接临时呼叫
          let msg = {
            type: "denyAdhoc",
            pwd: that.accessToken,
            uid: that.userInfo.userId,
            roomId: roomId,
          };
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
          that.connectWebsocketAction.closeConnect(connect);
        },
        doExitAdhoc: function (roomId, isClose) {
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (!connect) return;
          let msg = { type: "exitAdhoc" };
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
          if (isClose) {
            that.connectWebsocketAction.closeConnect(connect);
          }
        },
        doExitGroup: function (roomId) {
          //退出群组
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect.readyState != 1) return;
          if (!connect) return;
          let msg = { type: "exitGroup" };
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
          that.connectWebsocketAction.closeConnect(connect);
        },
        doRequestSpeak: function (roomId, grade) {
          //申请发言权
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          console.log(connect);
          if (connect.readyState != 1) return;
          let msg = { type: "reqTalk", grade: 1 };
          if (grade != undefined) {
            msg.grade = grade;
          }
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
        },
        doRequestCloseSpeak: function (roomId) {
          //释放发言权
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect.readyState != 1) return;
          if (!connect) return;
          let msg = { type: "exitTalk" };
          that.connectWebsocketAction.sendMessage(connect, JSON.stringify(msg));
        },
        doCloseTalkAction(roomId) {
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect == undefined || connect == null) {
            return false;
          }
          if (connect.readyState != 1) return;
          if (connect.talkType == "chat") {
            this.doExitGroup(roomId);
          } else {
            this.doExitAdhoc(roomId, true);
          }
        },
        doCloseAllTalkAction() {
          let connects = that.connectWebsocketAction.doGetAllConnect();
          if (connects.length === 0) return;
          for (let i = 0; i < connects.length; i++) {
            if (connects[i].talkType == "chat") {
              this.doExitGroup(connects[i].roomId);
            } else {
              this.doExitAdhoc(connects[i].roomId, true);
            }
          }
        },
      };
    },
    initPocMessageDeal() {
      let that = this;
      this.pocMessageDeal = {
        receiveMessage: function (msg, roomId) {
          //对讲消息 房间id
          msg = JSON.parse(msg);
          if (msg.length === 1) {
            let data = msg[0];
            if (data.type == "Buffer") {
              that.setOtherSpeak();
              console.log(data);
              this.doPocAudioDeal(data.data);
            } else {
              data.sessionId = roomId;
              switch (data.type) {
                case 4000:
                  this.pocMessageEventDeal(data);
                  break;
                case 4001:
                  this.pocMessageDataDeal(data);
                  break;
                default:
                  break;
              }
            }
          } else {
            for (let i = 0; i < msg.length; i++) {
              let data = msg[i];
              data.sessionId = roomId;
              switch (data.type) {
                case 4000:
                  this.pocMessageEventDeal(data);
                  break;
                case 4001:
                  this.pocMessageDataDeal(data);
                  break;
                case "Buffer":
                  this.doPocAudioDeal(data.data);
                  break;
                default:
                  break;
              }
            }
          }
        },
        pocMessageEventDeal: function (data) {
          console.log("pocMessageEventDeal", data);
          let state =
            data.States && data.States.length > 0 ? data.States[0].State : "";
          if (state == 1) {
            that.applyTalkSessionId = data.sessionId;
            that.speaker = "warning";
            that.callText = `正在呼叫${that.userNamePaltform}...`;
          }
          if (state == 2) {
            that.speaker = "success";
            that.callText = `正在和${that.userNamePaltform}通话中`;
            that.isTalking = true;
          }
          if (state == 3) {
            that.speaker = "error";
            that.callText = `${that.userNamePaltform}拒绝了通话`;
            that.closeShowTimer(222);
          }
          if (state == 4) {
            that.speaker = "error";
            that.callText = `${that.userNamePaltform}暂时无应答`;
            that.closeShowTimer(555);
          }
          switch (data.op) {
            case 0:
              break;
            case 1:
              break;
            default:
              break;
          }
        },
        pocMessageDataDeal: function (data) {
          // console.log("pocMessageDataDeal",data);
          switch (data.op) {
            case 1100:
              that.connectWebsocketAction.doSendTalkHeartPacket();
              break;
            case 1: //申请发言权
              if (data.result == 1) return;
              let connect = that.connectWebsocketAction.getConnectByConnectId(
                data.sessionId
              );
              that.pocRecordAction.startRecord(connect);
              that.pocRecordAction.doRecordSpeakState(true);
              break;
            case 4: //释放发言权
              that.pocRecordAction.doRecordSpeakState(false);
              break;
            case 2: // xx正在讲话	takingName
              break;
            case 5: //会话空闲
              break;
            case 3:
              break;
            case 1200: //临时呼叫
              that.connectWebsocketAction.doSendTalkHeartPacket();
              break;
            case 1201: //临时呼叫被叫
              that.connectWebsocketAction.doSendTalkHeartPacket();
              break;
            default:
              break;
          }
        },
        pocConnectCloseDeal: function (roomId) {
          let connect =
            that.connectWebsocketAction.getConnectByConnectId(roomId);
          if (connect === undefined) return;
          that.connectWebsocketAction.closeConnect(connect);
        },
        doPocAudioDeal: function (data) {
          //对讲语音流播放
          let buffer = new Int8Array(data).buffer;
          that.pocAudioPlay.feed(new Int16Array(buffer));
        },
      };
    },
    initPocRecordAction() {
      let that = this;
      this.pocRecordAction = {
        startRecord: function (wsConnect) {
          navigator.getUserMedia =
            navigator.getUserMedia || navigator.webkitGetUserMedia;
          if (!navigator.getUserMedia) {
            alert("浏览器不支持音频输入");
          } else {
            navigator.getUserMedia(
              {
                audio: true,
              },
              function (mediaStream) {
                that.pocRecordInit(
                  new that.audioRecorderAction(mediaStream, wsConnect)
                );
                if (that.pocRecord) {
                  that.pocRecord.start();
                }
              },
              function (error) {
                switch (error.message || error.name) {
                  case "PERMISSION_DENIED":
                  case "PermissionDeniedError":
                    console.info("用户拒绝提供信息。");
                    break;
                  case "NOT_SUPPORTED_ERROR":
                  case "NotSupportedError":
                    console.info("浏览器不支持硬件设备。");
                    break;
                  case "MANDATORY_UNSATISFIED_ERROR":
                  case "MandatoryUnsatisfiedError":
                    console.info("无法发现指定的硬件设备。");
                    break;
                  default:
                    console.info(
                      "无法打开麦克风。异常信息:" + (error.code || error.name)
                    );
                    break;
                }
              }
            );
          }
        },
        closeRecord: function () {
          if (that.pocRecord) {
            that.pocRecord.stop();
          }
        },
        doRecordSpeakState: function (speakIngFlag) {
          window.userRecordSpeakIngFlag = speakIngFlag;
        },
      };
    },
    pocRecordInit(rec) {
      this.pocRecord = rec;
    },
    initAudioRecorderAction() {
      let that = this;
      this.audioRecorderAction = function (stream, wsConnect) {
        this.sampleBits = 16; //输出采样数位16
        this.sampleRate = 8000; //输出采样率
        this.context = new AudioContext();
        this.resPcmData = [];
        this.audioInput = this.context.createMediaStreamSource(stream);
        let recorder = this.context.createScriptProcessor(2048, 1, 1);
        this.wsConnect = wsConnect;
        let audioData = {
          size: 0, //录音文件长度
          buffer: [], //录音缓存
          inputSampleRate: 48000, //输入采样率
          inputSampleBits: 16, //输入采样数位 8, 16
          outputSampleRate: this.sampleRate, //输出采样数位
          oututSampleBits: this.sampleBits, //输出采样率
          clear: function () {
            this.buffer = [];
            this.size = 0;
          },
          input: function (data) {
            this.buffer.push(new Float32Array(data));
            this.size += data.length;
          },
          compress: function () {
            //合并压缩
            //合并
            let data = new Float32Array(this.size);
            let offset = 0;
            for (let i = 0; i < this.buffer.length; i++) {
              data.set(this.buffer[i], offset);
              offset += this.buffer[i].length;
            }
            //压缩
            let compression = parseInt(
              this.inputSampleRate / this.outputSampleRate
            );
            let length = data.length / compression;
            let result = new Float32Array(length);
            let index = 0,
              j = 0;
            while (index < length) {
              result[index] = data[j];
              j += compression;
              index++;
            }
            return result;
          },
          encodePCM: function () {
            //将音频数据转为16位的pcm数据
            let sampleBits = Math.min(
              this.inputSampleBits,
              this.oututSampleBits
            );
            let bytes = this.compress();
            let dataLength = bytes.length * (sampleBits / 8);
            let buffer = new ArrayBuffer(dataLength);
            let data = new DataView(buffer);
            let offset = 0;
            for (let i = 0; i < bytes.length; i++, offset += 2) {
              let s = Math.max(-1, Math.min(1, bytes[i]));
              data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
            }
            return data;
          },
        };
        this.sendPcmData = function () {
          let sendData = audioData.encodePCM();
          let data = new Int16Array(sendData.buffer);
          let audioDataArr = Array.from(data);
          audioDataArr = audioDataArr.filter(function (t) {
            return t != 0;
          });
          let pcmData = this.doGetPcmData(audioDataArr);
          if (pcmData) {
            that.connectWebsocketAction.sendMessage(this.wsConnect, pcmData);
          }
          audioData.clear();
        };
        this.start = function () {
          window.dataSort = 0;
          window.timeLength = 0;
          this.audioInput.connect(recorder);
          recorder.connect(this.context.destination);
        };

        this.stop = function () {
          console.log(recorder);
          recorder.disconnect();
        };
        this.clear = function () {
          audioData.clear();
        };
        this.doGetPcmData = function (pcmData) {
          Array.prototype.push.apply(this.resPcmData, pcmData);
          if (this.resPcmData.length >= 480) {
            let result = this.resPcmData.slice(0, 480);
            this.resPcmData = this.resPcmData.slice(480);
            return new Int16Array(result);
          }
          return false;
        };

        recorder.onaudioprocess = function (e) {
          let inputBuffer = e.inputBuffer.getChannelData(0);
          audioData.input(inputBuffer);
          console.log(1111);
          if (wsConnect.readyState != 1 && userRecordSpeakIngFlag) {
            recorder.disconnect();
            return;
          }
          if (!userRecordSpeakIngFlag) {
            recorder.disconnect();
            return;
          }
          that.pocRecord.sendPcmData();
        };
      };
    },
    mqttConnect() {
      let that = this;
      this.userTopic = "shuguo/unicast/user@" + this.userInfo.userId;
      this.client = new Paho.MQTT.Client(
        this.mqttHost,
        this.mqttPort,
        "sg_demo" + Math.random()
      );
      this.client.onConnectionLost = onConnectionLost;
      this.client.onMessageArrived = onMessageArrived;
      this.client.onMessageDelivered = onMessageDelivered;
      this.client.connect({
        timeout: 60,
        useSSL: true,
        keepAliveInterval: 10,
        userName: that.userName,
        password: that.userPwd,
        onSuccess: onConnect,
      });
      function onConnect() {
        console.log("MQTT连接成功");
        that.client.subscribe(that.userTopic);
        that.client.send(
          "emitter/PocPresence/",
          JSON.stringify({
            UserID: that.userInfo.userId.toString(),
            DevID: that.getuuid(),
            State: 1,
          }),
          0
        );
      }
      function onConnectionLost(responseObject) {
        //断开连接
        if (responseObject.errorCode !== 0) {
          console.log("onConnectionLost:" + responseObject.errorMessage);
        }
      }
      function onMessageDelivered(requestObject) {
        console.log("onMessageDelivered", requestObject);
      }

      function onMessageArrived(message) {
        //订阅后的回调函数
        var msg = message.payloadString;
        var parseMsg = JSON.parse(msg);
        console.log("onMessageArrived", parseMsg);

        // 获取用户名
        let userInfo = "";
        if (parseMsg.data) {
          // that.isCaller = parseMsg.data.CallerUserID == that.userInfo.userId
          userInfo = that.onlineUser.find((el) => {
            return el.uid == parseMsg.data.CallerUserID;
          });
        }

        //临时呼叫消息 show判断防止二次被呼叫
        if (parseMsg.subtype === "adhocTrigger" && !that.show) {
          if (userInfo) {
            that.userNamePaltform = userInfo.displayName;
          }
          that.isComingTalk = true;
          that.speaker = "warning";
          that.comingTalkData = parseMsg.data;
          that.show = true;
          that.callText = `${
            that.userNamePaltform || parseMsg.data.CallerUserID
          }对您发起了临时通话`;
        }
        // 对方取消临时呼叫消息
        if (parseMsg.subtype === "adhocExit") {
          if (userInfo) {
            that.userNamePaltform = userInfo.displayName;
          }
          that.speaker = "error";
          // that.show = true;
          that.isComingTalk = false;
          // that.callText = `${that.userNamePaltform || parseMsg.data.CallerUserID}已取消对您发起的临时通话`
          that.callText = `通话已结束。`;
          that.closeShowTimer(333);
        }
      }
    },
    // 接听来电
    receiveComingAdhocCall() {
      this.pocTalkAction.adhocCall(
        this.comingTalkData.GroupID,
        this.comingTalkData.CallerUserID,
        true,
        0
      );
    },
    // 拒绝来电
    rejectComingAdhocCall() {
      // this.speaker = "warning"
      // this.callText = `您已拒绝通话`
      this.isComingTalk = false;
      this.pocTalkAction.adhocCall(this.comingTalkData.GroupID, null, true, 1);
    },
    closeShowTimer(flag = 1) {
      this.closeAllTalk();
      clearTimeout(this.timer);
      if (this.show) {
        this.timer = setTimeout(() => {
          console.log(flag);
          this.show = false;
        }, 3000);
      }
    },
    getuuid() {
      var uid = [];
      var hexDigits = "0123456789abcdefghijklmnopqrst";
      for (var i = 0; i < 32; i++) {
        uid[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      uid[6] = "4";
      uid[15] = hexDigits.substr((uid[15] & 0x3) | 0x8, 1);
      var uuid = uid.join("");
      return uuid;
    },
    closeAllTalk() {
      this.pocTalkAction.doCloseAllTalkAction();
      this.isMySpeaking = false;
      this.isTalking = false;
      this.isOtherSpeak = false;
    },
    setOtherSpeak() {
      // alert(1)
      if (this.timer2) {
        clearTimeout(this.timer2);
      }
      this.isOtherSpeak = true;
      this.timer2 = setTimeout(() => {
        this.isOtherSpeak = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="less">
.i-dispatch-container {
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  // left: calc(50% - 115px);
  // top: 200px;
  // transform: translate(calc(50% - 115px), 200px);
  left: 0;
  top: 0;
  pointer-events: all;
  z-index: 99999999;
  border-radius: 4px;
  min-width: 230px;

  /deep/ .el-alert {
    padding: 8px;
  }

  .head {
    padding: 4px 2px 2px 8px;
    font-size: 14px;
    cursor: move;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(185, 180, 180, 0.1);

    &:hover {
      background-color: rgba(185, 180, 180, 0.1);
    }
  }

  .close-btn {
    color: @main-color;
    font-size: 12px;
    cursor: pointer;
  }

  .icon-btn-container {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon-img {
      cursor: pointer;
      display: block;
      width: 60px;
      height: 60px;
    }

    .icon-img2 {
      cursor: pointer;
      display: block;
      width: 56px;
      height: 56px;
    }
  }

  .icon-btn-container2 {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-logo {
    width: 80px;
    height: 80px;
    display: block;
    margin: 20px auto 0;
    border-radius: 50%;
  }

  .name {
    font-size: 22px;
    color: #333333;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }

  .last {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: #333333;
  }

  .position {
    color: #333333;
    text-align: center;
    font-size: 14px;
  }

  .user-info {
    position: relative;
  }

  .err-msg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    width: 300px;
    height: 172px;
    top: 0;
    left: 0;
  }

  .tool {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding: 4px 0;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(10, 10, 10, 0.5);
    }

    .full-icon {
      cursor: pointer;
      margin-right: 4px;
    }
  }
}
.i-dispatch-container * {
  user-select: none !important;
}
</style>
