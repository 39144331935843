<template>
  <el-dialog :visible.sync="show" append-to-body width="510px">
    <div class="container">
      <el-form :model="params" label-width="100px" ref="form">
        <el-form-item label="告警信息" prop="taskType">
          <el-input readonly :value="`${dictFormatter(params.taskType,dictData.alarmTypeDict)}-${params.taskContent}`"/>
        </el-form-item>
        <el-form-item label="协助人员" prop="assistUserId" :rules="[{required:true,message:'请选择协助人员',trigger:'change'}]">
          <el-select v-model="params.assistUserId" style="width: 370px">
            <el-option :value="item.userId" v-for="(item,index) in userList" :key="index" :label="item.username"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { socketEvent } from '@/services/socket/socketFactory'
import mainMixins from '@/mixins/main-mixins'
import dictMixins from '@/mixins/dict-mixins'
import { createAssistTask, securityStaffInfoList } from '@/views/home/services'
import { EventsEnum } from '@/services/eventBus'

export default {
  name: 'his-assist-task',
  mixins:[mainMixins,dictMixins],
  data(){
    return {
      show:false,
      params:{
        assistUserId:""
      },
      userList:[],
      messageVM:null
    }
  },
  watch:{
    "$store.state.home.workType":function(e){
      this.getSecurityStaffData()
    }
  },
  methods:{
    cancel(){
      this.show = false
    },
    async handleSubmit(){
      await this.$refs.form.validate()
      let data = await createAssistTask(this.params)
      if(data.code !== 200 ) return this.$message.error(data.message)
      this.$message.success(data.message)
      this.messageVM.close() //处理完成关闭
      this.show = false //这个也关闭
      this.params = {
        assistUserId:""
      }
    },
    handleMessageClick(data,messageVM){
      this.messageVM = messageVM
      this.userList.filter(res=>res.id !== data.userId)
      this.params = {
        ...data,
        assistUserId: ""
      }
      this.show = true
    },
    async getSecurityStaffData(){
      let workType = this.$store.state.home.workType
      let res = await securityStaffInfoList({
        pageSize:10,
        pageIndex:1,
        workStatus:'1',
        workType:workType,
        areaId:1
      })
      if(res.code !== 200) return this.$message.error(res.message)
      this.userList = res.data
    },
    handleHisMessage(data){
      if(data && data.length){
        let that = this
        data.forEach(res=>{
          this.$notify({
            title: '请求协助',
            message: `任务【${res.taskName}】,点击处理`,
            duration: 0,
            type:'warning',
            showClose: false,
            onClick(){
              that.handleMessageClick(res,this)
            }
          });
        })
      }
    },
    handleHisMessageOne(data){
      this.handleHisMessage([data])
    },
    startHisAssistTask(){
      this.getDict("alarmTypeDict")
      // 发送指令
      this.$eventBus.$on(socketEvent.HIS_ASSIST_TASK,this.handleHisMessage)
      this.$eventBus.$on(socketEvent.ASSIST_TASK,this.handleHisMessageOne)
      // 获取当前人员信息
      this.getSecurityStaffData()
    },
  },
  mounted() {
    this.$eventBus.$on(EventsEnum.TASK_HIST_ASSIST_TASK,this.startHisAssistTask)
 
  },
  destroyed() {
    this.$eventBus.$off(EventsEnum.TASK_HIST_ASSIST_TASK,this.startHisAssistTask)
    this.$eventBus.$off(socketEvent.HIS_ASSIST_TASK,this.handleHisMessage)
    this.$eventBus.$off(socketEvent.ASSIST_TASK,this.handleHisMessageOne)
  }
}
</script>

<style scoped lang="less">
.footer{
  text-align: center;
}
</style>
