<template>
  <el-menu class="soar-menu"
           :default-active="activeMenu"
           :unique-opened="true"
           :collapse="$store.state.layout.visibleMenu"
           mode="vertical"
           router

           @select="handleSelectItem">
    <sidebar-item v-for="route in menus"
                  :key="route.path"
                  :item="route"/>
  </el-menu>
</template>

<script>
import SidebarItem from './SidebarItem'
import { mapGetters } from 'vuex'
export default {
  components: {
    SidebarItem
  },
  methods: {
    /**
     * 选择菜单项
     * @EventHandler
     */
    handleSelectItem () {

    },

  },
  computed: {
    /**
     * 当前激活的菜单
     * @return {string}
     */
    activeMenu () {
      return this.$route.path
    },
    ...mapGetters(['menus'])
  }
}
</script>


<style lang="less" scoped>
.soar-menu:not(.el-menu--collapse) {
  width: 200px;
}
.soar-menu {
  border-right-color: transparent;
  background-color: var(--soar-menu-bg);

  /deep/ .el-sub-menu__title {
    color: var(--soar-menu-title-color);
  }
}

/deep/ .el-menu{
  background-color: var(--soar-sub-menu-bg);
}

/deep/ .el-menu-item ,/deep/ .el-sub-menu__title ,/deep/.el-submenu__title{
  color: var(--soar-menu-title-color);
  &.is-active {
    color: var(--soar-menu-active-color);
    background-color: var(--soar-menu-item-hover-bg);
  }
  &:hover{
    background-color: var(--soar-menu-item-hover-bg);
  }
}



</style>
<style lang="less">
.el-menu--vertical{
  .el-menu{
    background-color: var(--soar-sub-menu-bg);
  }

  .el-menu-item ,.el-sub-menu__title ,.el-submenu__title{
    color: var(--soar-menu-title-color);
    &.is-active {
      color: var(--soar-menu-active-color);
      background-color: var(--soar-menu-item-hover-bg);
    }
    &:hover{
      background-color: var(--soar-menu-item-hover-bg);
    }
  }
}

</style>

