import axios from 'axios'
import Vue from 'vue'
import http from '@/libs/http'

require('webrtc-adapter')
/***
 * 融合通讯接口 上下文 i-dispatch
 * 方法都以 i-dispatch 开头!!!
 * 代理方式
 */

let iDispatchRequest = axios.create({
  baseURL:"/iDispatch",
  responseType: 'json',
  headers: { 'Content-Type': 'application/json;charset-utf-8' },
  timeout: 100000
})

export const IDispatchEventEnum = {
  //数据推送音视屏
  POLL:"POLL",
  INIT:"INIT"
}

export const ReasonEnum = {
  "wrong password":"密码错误",
  "user_logout":"用户未登录",
  "The_user_is_not_login":"用户未登录",
  "No_this_operator":"当前用户对讲机设备不存在，请检查。",
  "call_error":"呼叫失败"
}

export const WebrtcEventTypeEnum = {
  STARTING: "starting",
  STARTED: "started",
  CONNECTING: "connecting",
  LOGIN: "login",
  MAKECALL: "make_call",
  RING: "ring",
  ANSWER: "answer",
  HANGUP: "hangup",
  LOGOUT: "logout",
  DTMF: "dtmf",
  PTTREQUEST: "ptt_request",
  PTTRELEASE: "ptt_release",
  HEART: "heart",
  ONNEWCALL: "on_new_call",
  ONRING: "on_ring",
  ONRING183: "on_ring_183",
  ONANSWER: "on_answer",
  ONHANGUP: "on_hangup",
  ONPTTREQUEST: "on_ptt_request",
  ONPTTRELEASE: "on_ptt_release",
  ONDISCONNECT: "on_disconnect",
  INCOMING:"INCOMING",
  CALLING:"CALLING"
}

// request拦截器,在请求之前做一些处理
iDispatchRequest.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      'Authorization': window.sessionStorage.getItem('iDispatchToken')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)


// response 拦截器,数据返回后进行一些处理
iDispatchRequest.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      window.sessionStorage.setItem("iDispatchToken","")
      return Promise.reject(response.data.message)
    }
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

let keepTimer = null

// 强制退出登录
export function iDispatchForcelogout(data){
  return iDispatchRequest.post('/idispatch/forcelogout',data)
}

// 登录
async function iDispatchLogin(){
  let userInfo = window.sessionStorage.getItem("userInfo");
  userInfo = userInfo ? JSON.parse(userInfo) : {}
  if(!userInfo.iDispatchUserName) throw new Error(ReasonEnum['No_this_operator'])
  let data = {
    request:{
      '@id':'10000',
      '@type':'UserLogin',
      userName:userInfo.iDispatchUserName,
      userPwd: userInfo.iDispatchUserPwd
    }
  }
  await iDispatchForcelogout({username:userInfo.iDispatchUserName})
  return iDispatchRequest.post(`/idispatch/login`,data).then( async res=>{
    if(res.status !== 200){
      Vue.prototype.$message.error(ReasonEnum[res.reason])
      throw new Error(ReasonEnum[res.reason])
    }
    // 获取用户信息
    window.sessionStorage.setItem("iDispatchToken",res.tokensign)
    let userInfo = await iDispatchOperatorQuery()
    if(userInfo.status === 200){
      window.sessionStorage.setItem('iDispatchUserInfo',userInfo.eventdata)
    }
    let phoneConf = await iDispatchSoftPhoneConf()
    userInfo.eventdata.phoneConf = phoneConf.eventdata
    keepTimer = setInterval(()=>{
      iDispatchPoll()
    },3000)
    return userInfo.eventdata
  })
}

// 获取数据
function iDispatchPoll(){
  return iDispatchRequest.get(`/idispatch/poll`).then(async res=>{
    if(res.status === 400 && res.reason === 'The_user_is_not_login'){
      Vue.prototype.$message.info("对讲机设备退出登录,尝试重新连接登录!")
      clearInterval(keepTimer)
      // 执行重新登录
      await iDispatchLogin()
      Vue.prototype.$message.success("对讲机设备,连接成功!")
    }else{
      Vue.prototype.$eventBus.$emit(IDispatchEventEnum.POLL,res)
    }
    return res
  })
}

// 退出登录
export function iDispatchUserLogout(){
  clearInterval(keepTimer)
  return iDispatchRequest.get("/idispatch/userlogout")
}

// 挂断
export function iDispatchClearCall(data){
  return iDispatchRequest.post("/idispatch/clearcall",data)
}


// 单挂断
export function iDispatchMakeCall(data){
  return iDispatchRequest.post("/idispatch/makecall",data)
}

// 获取当前登录用户信息
export function iDispatchOperatorQuery(){
  return iDispatchRequest.post("/idispatch/operatorquery",)
}

// 获取电话配置信息
export function iDispatchSoftPhoneConf(){
  return iDispatchRequest.get('/idispatch/softphoneconf')
}

// 根据设备号码获取安保平台用户信息
export function getUserInfoFroDevicePhone(data){
  return http.post("/sys-user/getInfoByPhone",data)
}

export default class IDispatchManager {

  // 当前用户号码
  tel = ""

  // 当前用户密码
  pwd = ""

  // 心跳定时器
  webrtcSocketTimer = null

  // webrtcSocket
  webrtcSocket = null

  // webRtc实例子 连接
  webrtcConnection = null

  // 流媒体
  mediaStream = null

  // 传进来的流
  outMediaStream = null

  // 媒体元素视频通话使用
  mediaElement = null

  // 音频元素
  audioElement = null

  // 来电铃声
  incomingAudio = null

  // socketUrl
  socketUrl = 'wss://121.37.165.92:8888/webrtc2sip_ws/webrtcMedia'

  // 是否主动拨打电话
  callFlag = false

  // 对方号码
  targetPhone = ""

  constructor() {
    return
    if(process.env.VUE_APP_EASY_MODE === 'true') return
    iDispatchLogin().then( res=>{
      this.tel = res?.mainTel
      res?.phoneConf?.forEach(item=>{
        if(item.phone === this.tel){
          this.pwd = item?.password
        }
      })
      // 创建音频元素
      this.audioElement = document.createElement("audio")
      this.audioElement.autoplay = true
      // 创建来电铃声
      this.incomingAudio = document.createElement("audio")
      this.incomingAudio.autoplay = true
      this.incomingAudio.src = require("@/assets/sound/ringtone.wav")
      this.incomingAudio.loop = true
      this.incomingAudio.pause()
      // 初始化webrtcSocket
      this.createSocket()
    })
  }

  async createWebrtcLink(message){
    this.webrtcConnection = new RTCPeerConnection({
      iceServers:[]
    })

    this.webrtcConnection.onicecandidate = (e)=>{
      if (e.candidate) {
        this.webrtcSocket.send(JSON.stringify({
          type: WebrtcEventTypeEnum.ANSWER,
          from: message.from,
          sdp:  this.webrtcConnection.localDescription.sdp
        }));
      }
    }

    this.webrtcConnection.onnegotiationneeded = (e) => {
      this.webrtcConnection.setRemoteDescription(new RTCSessionDescription({
        type: "offer",
        sdp: message.sdp
      }))
      this.webrtcConnection.createAnswer().then(answer=>{
        this.webrtcConnection.setLocalDescription(answer);
      })
    }

    this.webrtcConnection.ontrack = (e) => {
      this.outMediaStream = e.streams[0];
      if(this.mediaElement){
        this.mediaElement && (this.mediaElement.srcObject = e.streams[0])
      }else{
        this.audioElement && (this.audioElement.srcObject = e.streams[0])
      }
    }

    let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }).catch(err=>{
      this.onCallState && this.onCallState(WebrtcEventTypeEnum.HANGUP)
      Vue.prototype.$message.error("获取媒体设备失败")
      throw new Error(err)
    })
    this.mediaStream = stream
    stream.getTracks().forEach((a) => {
      this.webrtcConnection?.addTrack(a, stream)
    })
  }

  // 创建 socket
  createSocket(){
    this.webrtcSocket = new WebSocket(this.socketUrl)

    //连接成功
    this.webrtcSocket.onopen = (e) => {
      this.webrtcSocket.send(JSON.stringify({
        "type":WebrtcEventTypeEnum.LOGIN,
        "user_name": this.tel,
        "pass_word": this.pwd,
        "user_agent":"other"
      }))

      // 开启心跳
      this.webrtcSocketTimer = setInterval(()=>{
        this.webrtcSocket.send(JSON.stringify({
          "type":WebrtcEventTypeEnum.HEART,
          "user_name":this.tel
        }))
      },20000)
    };

    //server端请求关闭
    this.webrtcSocket.onclose = (e) =>{
      this.webrtcSocket.close()
      this.webrtcSocket = null
      this.webrtcSocketTimer && clearInterval(this.webrtcSocketTimer)
      this.webrtcSocketTimer = null
    };

    //error
    this.webrtcSocket.onerror = (e) =>{
      this.webrtcSocket.close()
      this.webrtcSocket = null
      this.webrtcSocketTimer && clearInterval(this.webrtcSocketTimer)
      this.webrtcSocketTimer = null
      console.error("webrtc失去链接 3s后重新链接")
      // 3秒后重新连接
      setTimeout(()=>{
        this.createSocket()
      },30000)
    };

    //收到消息
    this.webrtcSocket.onmessage = async (event)=> {
      let message = JSON.parse(event.data)
      switch (message.type) {
        // 应答
        case WebrtcEventTypeEnum.ANSWER:
          await this.webrtcConnection.setRemoteDescription(message.sdp);
          break
        case WebrtcEventTypeEnum.ONNEWCALL:

          this.webrtcSocket.send(JSON.stringify({
            "type":WebrtcEventTypeEnum.RING,
            "from":this.tel
          }))
          // 接电话
          if(!this.callFlag){
            this.incomingAudio.play()
            this.targetPhone = message.from
            this.onCallState && this.onCallState(WebrtcEventTypeEnum.INCOMING,message,)
          }else{
            // 打电话
            this.onCallState && this.onCallState(WebrtcEventTypeEnum.CALLING,message)
            this.createWebrtcLink(message)
          }
          break

        case WebrtcEventTypeEnum.ONHANGUP:
          if(!this.callFlag){
            this.incomingAudio.pause()
          }
          this.callFlag = false
          this.webrtcConnection?.close()
          this.webrtcConnection = null
          this.mediaStream && this.mediaStream?.getTracks()?.forEach((a) =>{
            this.mediaStream.removeTrack(a);
            a.stop();
          })
          this.mediaStream = null
          this.onCallState && this.onCallState(WebrtcEventTypeEnum.HANGUP)
          break
      }

    };
  }


  // 设置媒体元素
  setMediaElement(element){
    this.mediaElement = element
  }

  // 监听挂断
  onCallState(){}

  // 是否接听
  isAnswer = false
  // 接听
  async answer(message){
    if(!message) return console.error('no args message')
    this.isAnswer = true
    this.onCallState && this.onCallState(WebrtcEventTypeEnum.CALLING,message)
    this.incomingAudio.pause()
    await this.createWebrtcLink(message)
    this.isAnswer = false
  }

  // 拒接
  hangup(){
    if(this.callFlag){
      this.clearCall()
    }else{
      // 如果接听了马上挂断 需要等到接听完成在挂断电话
      if(this.isAnswer){
        return setTimeout(()=>{
          this.hangup()
        },1000)
      }
      this.onCallState && this.onCallState(WebrtcEventTypeEnum.HANGUP)
      this.webrtcSocket.send(JSON.stringify({
        "type":WebrtcEventTypeEnum.HANGUP,
        "from":this.tel
      }))
      if(!this.callFlag){
        this.incomingAudio.pause()
      }
      this.callFlag = false
      this.webrtcConnection?.close()
      this.webrtcConnection = null
      this.mediaStream && this.mediaStream?.getTracks()?.forEach((a) =>{
        this.mediaStream.removeTrack(a);
        a.stop();
      })
      this.mediaStream = null
    }
  }

  // 检查对讲机是否在线
  checkStatus(alert = true){
    let isOk = !!window.sessionStorage.getItem('iDispatchToken')
    if(alert && !isOk){
      Vue.prototype.$message.error("当前用户未维护设备信息，设备未登录。")
    }
    return isOk
  }

  // 视频单呼
  makeVideoCall(number){
    return this.makeCall(number,{
      callType:'video'
    })
  }

  // 单呼
  async makeCall(number,params){
    if(!this.checkStatus()) return
    if(number === this.tel) return Vue.prototype.$message.warning("不能呼叫自己")
    if(!number) return Vue.prototype.$message.warning("号码不存在")
    this.targetPhone = number
    let data = await iDispatchMakeCall({
      "request":{
        "@id":"100000",
        "@type":"MakeCall",
        "callingDevice":this.tel,
        "called":{
          "calledDevice":number,
        },
        "callType":"audio",
        "duplexMode":'full',
        ...params,
      }
    })
    if(data.status === 200){
      let audio = document.createElement("audio")
      audio.autoplay = true
      audio.src = require("@/assets/sound/ringbacktone.wav")
      audio.loop = false
      audio.play()
      this.callFlag = true
      return true
    }
    throw new Error(ReasonEnum['call_error'])
  }

  async clearCall(){
    if(!this.checkStatus()) return
    let data = await iDispatchClearCall()
    return data.status === 200
  }

}

