<template>
  <el-menu-item :index="item.path" v-if="!item.children || !item.children.length"  v-show="!item.hidden">
    <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title" />
  </el-menu-item>
  <el-menu-item :index="item.children[0].path" v-else-if="item.children.length === 1"  v-show="!item.hidden">
    <item
          v-if="item.children[0].meta"
          :icon="item.children[0].meta && item.children[0].meta.icon"
          :title="item.children[0].meta.title" />
  </el-menu-item>
  <el-submenu v-else
              :index="item.path"
              v-show="!item.hidden"
              popper-append-to-body>
    <template slot="title">
      <item v-if="item.meta"
            :icon="item.meta && item.meta.icon"
            :title="item.meta.title" />
    </template>
    <sidebar-item v-for="child in item.children"
                  :key="child.path"
                  :item="child" />
  </el-submenu>
</template>

<script>
import Item from './Item'
import AppLink from './Link'
export default {
  name: 'SidebarItem',
  components: { Item ,AppLink},
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      onlyOneChild: null,
      hasOneShowingChild: false
    };
  },
}
</script>
