export default {
  namespaced: true,

  state:{
    visibleMenu:false
  },
  mutations:{
    toggleVisibleMenu(state){
      state.visibleMenu = !state.visibleMenu
    }
  }

}
