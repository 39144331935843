/**
 * 将时间戳格式化为字符串
 * @param  {Number} timestamp 时间戳
 * @param  {String} fmt       可选目标格式
 * @return {String}           格式化后的日期时间
 * @example fmt = "yyyy年MM月dd日 hh:mm:ss.S" 输出: 2018年06月07日 10:41:08.133
 * @example fmt = "yyyy-MM-dd hh:mm:ss" 输出: 2018-06-07 10:41:08
 * @example fmt = "yy-MM-dd hh:mm:ss" 输出: 18-06-07 10:41:08
 * @example fmt = "yy-M-d hh:mm:ss" 输出: 18-6-7 10:41:08
 */
export function formatDate(val, fmt = "yyyy-MM-dd hh:mm:ss") {
    if (val == null) return "";
    const date = new Date(val);
    const o = {
        "y+": date.getFullYear(),
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((date.getMonth() + 3) / 3),
        "S+": date.getMilliseconds(),
    };
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            if (k === "y+") {
                fmt = fmt.replace(RegExp.$1, ("" + o[k]).substr(4 - RegExp.$1.length));
            } else if (k === "S+") {
                let lens = RegExp.$1.length;
                lens = lens === 1 ? 3 : lens;
                fmt = fmt.replace(
                    RegExp.$1,
                    ("00" + o[k]).substr(("" + o[k]).length - 1, lens)
                );
            } else {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1
                        ? o[k]
                        : ("00" + o[k]).substr(("" + o[k]).length)
                );
            }
        }
    }
    return fmt;
}

// 将日期转换成时间戳
export const timeToTimestamp = (date) => {
    const datetime = new Date(date);
    return datetime.getTime();
};


/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
    if (document.addEventListener) {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.addEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.attachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
    if (document.removeEventListener) {
        return function (element, event, handler) {
            if (element && event) {
                element.removeEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event) {
                element.detachEvent('on' + event, handler)
            }
        }
    }
})()

// 函数防抖
export function debounce(fn, delay = 200) {
    // 记录上一次的延时器
    let timer = null;
    return function () {
        const args = arguments;
        const that = this;
        // 清除上一次延时器
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
}

// 函数节流
export function throttle(fn, delay = 200) {
    let lastTime;
    let timer;
    return function () {
        const args = arguments;
        // 记录当前函数触发的时间
        const nowTime = Date.now();
        if (lastTime && nowTime - lastTime < delay) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                // 记录上一次函数触发的时间
                lastTime = nowTime;
                // 修正this指向问题
                fn.apply(this, args);
            }, delay);
        } else {
            lastTime = nowTime;
            fn.apply(this, args);
        }
    };
}


export function loadScript(url, attr = {}, once = true) {
    if (once) {
        // 成功加载过一次就跳过
        const els = document.querySelectorAll("script");
        const matched = Array.from(els).find((x) => x.src?.indexOf(url) > -1);
        if (matched && matched.getAttribute("data-status") === "load") {
            return Promise.resolve();
        }
    }
    return new Promise((resolve, reject) => {
        const el = document.createElement("script");
        el.src = url;
        Object.keys(attr).forEach((k) => {
            el.setAttribute(k, attr[k]);
        });
        el.addEventListener("load", () => {
            el.setAttribute("data-status", "load");
            resolve(el);
        });
        el.addEventListener("error", () => {
            el.setAttribute("data-status", "error");
            reject(new Error(`加载失败:${url}`));
        });
        document.body.appendChild(el);
    });
}

export function loadStyle(url, attr = {}, once = true) {
    if (once) {
        // 成功加载过一次就跳过
        const els = document.querySelectorAll("link");
        const matched = Array.from(els).find((x) => x.href?.indexOf(url) > -1);
        if (matched && matched.getAttribute("data-status") === "load") {
            return Promise.resolve();
        }
    }
    return new Promise((resolve, reject) => {
        const el = document.createElement("link");
        el.rel = "stylesheet";
        el.href = url;
        Object.keys(attr).forEach((k) => {
            el.setAttribute(k, attr[k]);
        });
        el.addEventListener("load", () => {
            el.setAttribute("data-status", "load");
            resolve(el);
        });
        el.addEventListener("error", () => {
            el.setAttribute("data-status", "error");
            reject(new Error(`加载失败:${url}`));
        });
        document.head.appendChild(el);
    });
}

// 异步延时器
export function timeoutPromise(t) {
    return new Promise((resolve) => {
        setTimeout(resolve, t)
    })
}

//字典翻译
export function dictTranslate(value, dictData) {
    if (value !== 0 && !value) return "-"
    if (!dictData || !Object.keys(dictData).length) return value
    if (dictData instanceof Array) {
        return dictData.filter(res => res.dictValue === value).map(res => res.dictTitle).join(",") || "-"
    }
    if (typeof dictData === "object") {
        return dictData[value] || "-"
    }
    return "-"
}


/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
    let res = []
    let temp = {}
    for (let i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (let k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}


/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}


export function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

export function downFile(response) {
    let filename = response.headers['content-disposition'] ? response.headers['content-disposition'].split(';')[1].split('filename=')[1] : new Date().valueOf()
    filename = decodeURI(filename)

    var blob = new Blob([response.data]);
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
}

/**
     * 坐标集合的最西南角
     * @param {*} list
     *  list 是接口获取的点 的数组
     */
export function getSW(list) {
    let south = null;
    let west = null;
    for (let item of list) {
        if ((west && item.longitude < west) || !west) {
            west = item.longitude;
        }
        if ((south && item.latitude < south) || !south) {
            south = item.latitude;
        }
    }
    return [west, south];
}

/**
 *  最东北角
 * @param {*} list
 */
export function getNE(list) {
    let north = null;
    let east = null;
    for (let item of list) {
        if ((east && item.longitude > east) || !east) {
            east = item.longitude;
        }
        if ((north && item.latitude > north) || !north) {
            north = item.latitude;
        }
    }

    return [east, north];
}


/**
 *  base64图片转为二进制文件流(binary)
 */
export function base64toFile(base64URL,filename) {
  if(base64URL.length<100){return}
  filename = filename?filename:'file';
  let arr = base64URL.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
	u8arr[n] = bstr.charCodeAt(n)
  }
  let file = new File([u8arr], `${filename}.${suffix}`, {
	type: mime
  })
  return file
}

/**
 * 播放告警音频
 */
export function playAudio(){
    if(process.env.VUE_APP_EASY_MODE === 'true') return
    return debounce(()=>{
        let audio = document.createElement("audio")
        audio.autoplay = true
        audio.src = require("@/assets/sound/alarm.mp3")
        audio.loop = false
        audio.play()
    },2000)()
}

export const IsMac = /macintosh|mac os x/i.test(navigator.userAgent);
