/**
 * 任务socket
 */
import SocketFactory, { socketEvent } from '@/services/socket/socketFactory'
import store from '@/store'

export default class TaskSocket extends  SocketFactory {

  url = process.env.VUE_APP_SOCKET_URL;
  constructor() {
    super()
    let userInfo = window.sessionStorage.getItem("userInfo");
    if(!userInfo) return console.error("当前用户不存在")
    userInfo = JSON.parse(userInfo)
    this.url = this.url + userInfo.id
    this.init()
  }

  onmessage = (msg)=>{
    // console.log(msg,'--asd')
  }

  onopen = ()=>{
    super.onopen();
    // 全局历史的任务
    this.send({cmd:socketEvent.HIS_ASSIST_TASK,data:null})
    this.send({cmd:socketEvent.SITE_QUERY,data:{"workType":"0","workStatus":"1","areaId": store.state.scene.sceneData?.areaInfo?.id || 1}})
  }


}
