import Vue from "vue";
import Vuex from "vuex";
import getters from "@/store/getters";
import home from './modules/home';
import layout from './modules/layout'
import menu from './modules/menu'
import taskSocket from '@/store/modules/taskSocket'
import iDispatch from  '@/store/modules/iDispatch'
import shuguoTalk from  '@/store/modules/shuguoTalk'
import scene from '@/store/modules/scene'
import sceneAMap from '@/store/modules/sceneAMap'


Vue.use(Vuex);

// 不使用自动注册的方式 方便idea 链路提示调试
// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context("./modules", true, /\.js$/);
//
// // you do not need `import app from './modules/app'`
// // it will auto require all vuex model from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
//   const value = modulesFiles(modulePath);
//   modules[moduleName] = value.default;
//   return modules;
// }, {});

export default new Vuex.Store({
  modules:{
    home,
    layout,
    menu,
    taskSocket,
    scene,
    sceneAMap,
    // iDispatch
    shuguoTalk
  },
  getters,
});
