export const totalTypeDict = [
    {
        dictTitle:"人流监测",
        dictValue:"1007"
    },
    {
        dictTitle:"危险水域",
        dictValue:"1001"
    },
    {
        dictTitle:"钓鱼区域",
        dictValue:"1002"
    },
    {
        dictTitle:"山体",
        dictValue:"1003"
    },
    {
        dictTitle:"广场",
        dictValue:"1004"
    },
    {
        dictTitle:"岗亭",
        dictValue:"1005"
    },
    {
        dictTitle:"防火区域",
        dictValue:"1006"
    }
]

export const alarmTypeDict = [
    {
        dictTitle:'钓鱼',
        dictValue:'2001'
    },
    {
        dictTitle:'危险水域',
        dictValue:'2002'
    },
    {
        dictTitle:'打架斗殴',
        dictValue:'2003'
    },
    {
        dictTitle:'占道经营',
        dictValue:'2004'
    },
    {
        dictTitle:'噪音',
        dictValue:'2005'
    },
    {
        dictTitle:'其他',
        dictValue:'2006'
    },
    {
        dictTitle:'山体滑坡',
        dictValue:'2007'
    },
    {
        dictTitle:'宠物',
        dictValue:'2008'
    },
    {
        dictTitle:'自行车',
        dictValue:'2009'
    },
    {
        dictTitle:'动态客流',
        dictValue:'2010'
    },
    {
        dictTitle:'明火识别',
        dictValue:'2011'
    },
    {
        dictTitle:'区域入侵',
        dictValue:'2012'
    },
    {
        dictTitle:'人脸抓拍',
        dictValue:'2013'
    }
]

export const healthCodeDict = [
    {
        dictTitle:'绿码',
        dictValue:'3001'
    },
    {
        dictTitle:'黄码',
        dictValue:'3002'
    },
    {
        dictTitle:'红码',
        dictValue:'3003'
    }
]


export const attendanceStatusDict = [
    {
        dictTitle:'正常',
        dictValue:'4001'
    },
    {
        dictTitle:'迟到',
        dictValue:'4002'
    },
    {
        dictTitle:'早退',
        dictValue:'4003'
    },
    {
        dictTitle:'请假',
        dictValue:'4004'
    },
    {
        dictTitle:'旷工',
        dictValue:'4005'
    },
    {
        dictTitle:'未打卡',
        dictValue:'4006'
    }
]

export const taskStatusDict = [
    {
        dictTitle:'未指派',
        dictValue:'0'
    },
    {
        dictTitle:'已指派',
        dictValue:'1'
    },
    {
        dictTitle:'已结束',
        dictValue:'2'
    }
]

export const alarmStatusDict = [
    {
        dictTitle:'未进行',
        dictValue:'0'
    },
    {
        dictTitle:'进行中',
        dictValue:'1'
    },
    {
        dictTitle:'已结束',
        dictValue:'2'
    },
    {
        dictTitle:'识别错误',
        dictValue:'-1'
    }
]

export const userTypeDict = [
    {
        dictTitle:'领导',
        dictValue:'5000'
    },
    {
        dictTitle:'保安',
        dictValue:'5001'
    }
]

export const itemStateDict = [
    {
        dictTitle:'未开始',
        dictValue:'1'
    },
    {
        dictTitle:'进行中',
        dictValue:'2'
    },
    {
        dictTitle:'已结束',
        dictValue:'3'
    }
]

export const securityStateDict = [
    {
        dictTitle:'确认完成',
        dictValue:'1'
    },
    {
        dictTitle:'已完成',
        dictValue:'2'
    },
]

export const algorithmCompanyDict = [
    {
        dictTitle:'百度',
        dictValue:'bd'
    },
    {
        dictTitle:'旷世',
        dictValue:'ks'
    },
    {
        dictTitle:'百度飞桨',
        dictValue:'easydl'
    },
    {
        dictTitle:'海康',
        dictValue:'hik'
    },
    {
        dictTitle:'电信',
            dictValue:'dx'
    }
]

export const checkDict = [
    {
        dictTitle:'是',
        dictValue:'1'
    },
    {
        dictTitle:'否',
        dictValue:'0'
    }
]

export const areaDict = [
    {
        dictTitle:'西湖',
        dictValue: 1
    },
    {
        dictTitle:'左海',
        dictValue: 2
    }
]

export const noticeTypeDict = [
    {
        dictTitle:'外部',
        dictValue: "6000"
    },
    {
        dictTitle:'内部',
        dictValue: "6001"
    }
]

export const consultTypeDict = [
    {
        dictTitle:'保安证',
        dictValue: "7000"
    },
    {
        dictTitle:'初级职业等级证书',
        dictValue: "7001"
    },
    {
        dictTitle:'中级职业等级证书',
        dictValue: "7002"
    },
    {
        dictTitle:'高级职业等级证书',
        dictValue: "7003"
    },
    {
        dictTitle:'安检证',
        dictValue: "7004"
    }
]

export const sexDict = [
    {
        dictTitle:'女',
        dictValue:'1'
    },
    {
        dictTitle:'男',
        dictValue:'0'
    }
]

export const expectTypeDict = [
    {
        dictTitle:'综合专家',
        dictValue:'8000'
    },
    {
        dictTitle:'工程专家',
        dictValue:'8001'
    },
    {
        dictTitle:'咨询专家',
        dictValue:'8002'
    }
]

export const bidStatusDict = [
    {
        dictTitle:'招标',
        dictValue:'9000'
    },
    {
        dictTitle:'中标',
        dictValue:'9001'
    },
    {
        dictTitle:'拟建',
        dictValue:'9002'
    }
]

export const certificateDict = [
    {
        dictTitle:'保安证',
        dictValue:'10000'
    },
    {
        dictTitle:'救护员证',
        dictValue:'10001'
    },
    {
        dictTitle:'安全生产管理员',
        dictValue:'10002'
    },
    {
        dictTitle:'消控证',
        dictValue:'10003'
    }
]

export const levelDict = [
    {
        dictTitle:'高级',
        dictValue:'3'
    },
    {
        dictTitle:'中级',
        dictValue:'2'
    },
    {
        dictTitle:'初级',
        dictValue:'1'
    }
]


export const dutyTypeDict = [
    {
        dictTitle:'白班',
        dictValue:'1'
    },
    {
        dictTitle:'夜班',
        dictValue:'0'
    }
]

export const bidTypeDict= [
    {
        dictTitle:'安保',
        dictValue:'11000'
    },
    {
        dictTitle:'物业',
        dictValue:'11001'
    },
]

export const approveTypeDict= [
    {
        dictTitle:'离岗',
        dictValue:'12000'
    },
    {
        dictTitle:'公出',
        dictValue:'12001'
    },
]


export const approveStatusDict= [
    {
        dictTitle:'审核中',
        dictValue:'0'
    },
    {
        dictTitle:'通过',
        dictValue:'1'
    },
    {
        dictTitle:'驳回',
        dictValue:'2'
    },
]

export const sentryBoxTypeDict= [
    {
        dictTitle:'固定岗',
        dictValue:'0'
    },
    {
        dictTitle:'巡逻岗',
        dictValue:'1'
    }
]

export const patrolGuidelinesTypeDict= [
    {
        dictTitle:'按序巡逻',
        dictValue:'0'
    },
    {
        dictTitle:'无序巡逻',
        dictValue:'1'
    },
    {
        dictTitle:'范围巡逻',
        dictValue:'2'
    },
]

export const classesTypeDict= [
    {
        dictTitle:'固定班次',
        dictValue:'0'
    },
    {
        dictTitle:'巡逻班次',
        dictValue:'1'
    },
    {
        dictTitle:'临时固定班次',
        dictValue:'2',
        disabled:true
    },
    {
        dictTitle:'临时巡逻班次',
        dictValue:'3',
        disabled:true
    }
]

export const temporaryClassesTypeDict= [
    {
        dictTitle:'临时固定班次',
        dictValue:'2'
    },
    {
        dictTitle:'临时巡逻班次',
        dictValue:'3'
    }
]

export const weekDict= [
    {
        dictTitle:'周一',
        dictValue:'1'
    },
    {
        dictTitle:'周二',
        dictValue:'2'
    },
    {
        dictTitle:'周三',
        dictValue:'3'
    },
    {
        dictTitle:'周四',
        dictValue:'4'
    },
    {
        dictTitle:'周五',
        dictValue:'5'
    },
    {
        dictTitle:'周六',
        dictValue:'6'
    },
    {
        dictTitle:'周日',
        dictValue:'7'
    },
]

export const dailyPaperStateDict= [
    {
        dictTitle:'等待',
        dictValue:'0'
    },
    {
        dictTitle:'完成',
        dictValue:'1'
    },
    {
        dictTitle:'退回',
        dictValue:'2'
    }
]

export const isMorrowDict= [
    {
        dictTitle:'当日',
        dictValue:'当日'
    },
    {
        dictTitle:'次日',
        dictValue:'次日'
    }
]

export const checkAndApproveStateDict= [
    {
        dictTitle:'请选择审阅状态',
        dictValue:'0'
    },
    {
        dictTitle:'完成',
        dictValue:'1'
    },
    {
        dictTitle:'退回',
        dictValue:'2'
    }
]

export const clockInTypeDict= [
    {
        dictTitle:'上班卡',
        dictValue:'0'
    },
    {
        dictTitle:'下班卡',
        dictValue:'1'
    },
    {
        dictTitle:'加班卡',
        dictValue:'2'
    }
]


export const applyStateDict= [
    {
        dictTitle:'等待临时人员指派',
        dictValue:'1000'
    },
    {
        dictTitle:'指派人数不足所需人数',
        dictValue:'1001'
    },
    {
        dictTitle:'人数达到所需人数,完成申请',
        dictValue:'1002'
    }
]

export const shiftChangeStateDict= [
    {
        dictTitle:'等待接班',
        dictValue:'2000'
    },
    {
        dictTitle:'完成接班',
        dictValue:'2001'
    }
]

export const askForLeavesTypeDict= [
    {
        dictTitle:'事假',
        dictValue:'3010'
    },
    {
        dictTitle:'病假',
        dictValue:'3011'
    },
    {
        dictTitle:'婚假',
        dictValue:'3012'
    },
    {
        dictTitle:'产假',
        dictValue:'3013'
    }
]

export const askForLeavesStateDict= [
    {
        dictTitle:'等待审核',
        dictValue:'3000',
        disable:true
    },
    {
        dictTitle:'批准',
        dictValue:'3001'
    },
    {
        dictTitle:'驳回',
        dictValue:'3002'
    }
]

export const ClassesEnableDict= [
    {
        dictTitle:'禁用',
        dictValue:'0'
    },
    {
        dictTitle:'启用',
        dictValue:'1'
    }
]

export const trainTypeDict= [
    {
        dictTitle:'理念培训',
        dictValue:'0'
    },
    {
        dictTitle:'心态培训',
        dictValue:'1'
    },
    {
        dictTitle:'能力培训',
        dictValue:'2'
    },
    {
        dictTitle:'其他培训',
        dictValue:'3'
    }
]

export const ItemClassifyDict= [
    {
        dictTitle:'考勤',
        dictValue:'0'
    },
    {
        dictTitle:'巡逻',
        dictValue:'1'
    }
]

export const scoreItemDict= [
    {
        dictTitle:'迟到',
        dictValue:'100'
    },
    {
        dictTitle:'早退',
        dictValue:'101'
    },{
        dictTitle:'正常',
        dictValue:'102'
    },{
        dictTitle:'旷工',
        dictValue:'103'
    },{
        dictTitle:'缺勤',
        dictValue:'104'
    },{
        dictTitle:'请假',
        dictValue:'105'
    },
]

export const patrolStatusDict= [
    {
        dictTitle:'已结束',
        dictValue:'0'
    },
    {
        dictTitle:'进行中',
        dictValue:'1'

    }
]

export const rewardsPunishmentsDict= [
    {
        dictTitle:'奖励',
        dictValue:'1'
    },
    {
        dictTitle:'惩罚',
        dictValue:'2'
    },
]


export const customerTypeDict=[
    {
        dictTitle:'普通用户',
        dictValue:'1'
    },
    {
        dictTitle:'团体用户',
        dictValue:'2'
    }
]

export const editionDict=[
    {
        dictTitle:'强制',
        dictValue:'1'
    },
    {
        dictTitle:'非强制',
        dictValue:'2'
    }
    ]

export const policeState=[
    {
        dictTitle:'提交',
        dictValue:'1'
    },
    {
        dictTitle:'接警',
        dictValue:'2'
    },
    {
        dictTitle:'完成',
        dictValue:'3'
    }
]

export const disposeState=[
    {
        dictTitle:'是',
        dictValue:'1'
    },
    {
        dictTitle:'否',
        dictValue:'2'
    }
]

export const disposeStateDict = [
    {
        dictTitle:'确认完成',
        dictValue:'2'
    },
    {
        dictTitle:'已完成',
        dictValue:'1'
    },
]

export const idNumberCheckStateDict = [
    {
        dictTitle:'身份未提交',
        dictValue:'0'
    },
    {
        dictTitle:'身份待审核',
        dictValue:'1'
    },
    {
        dictTitle:'身份不通过',
        dictValue:'2'
    },
    {
        dictTitle:'身份已通过',
        dictValue:'3'
    },
]

export const idNumberCheckDict = [
    {
        dictTitle:'身份待审核',
        dictValue:'1'
    },
    {
        dictTitle:'身份不通过',
        dictValue:'2'
    },
    {
        dictTitle:'身份通过',
        dictValue:'3'
    },
]

export const platformTypeDict = [
    {
        dictTitle:'liveGBS',
        dictValue:'1'
    },
    {
        dictTitle:'电信',
        dictValue:'2'
    },
]


export const kqfs = [
    {
        dictTitle:'考勤机的人脸识别',
        dictValue:1
    },
    {
        dictTitle:'指纹考勤打卡',
        dictValue:2
    },
    {
        dictTitle:'手机app考勤打卡',
        dictValue:3
    }
]


export const jflx = [
    {
        dictTitle:'水费',
        dictValue:1
    },
    {
        dictTitle:'电费',
        dictValue:2
    },
    {
        dictTitle:'设备维修费',
        dictValue:3
    },
    {
        dictTitle:'停车费',
        dictValue:4
    },
    {
        dictTitle:'其他',
        dictValue:5
    },
]

export  const tzgg = [
    {
        dictTitle:'重要信息',
        dictValue:1
    },
    {
        dictTitle:'紧急通知',
        dictValue:2
    },
    {
        dictTitle:'教学楼动态信息',
        dictValue:3
    },
    {
        dictTitle:'住宿楼动态信息',
        dictValue:4
    },
    {
        dictTitle:'办公楼动态信息',
        dictValue:5
    },
]


export const fklx = [
    {
        dictTitle:'意见反馈',
        dictValue:1
    },
    {
        dictTitle:'投诉意见',
        dictValue:2
    },
    {
        dictTitle:'噪音投诉',
        dictValue:3
    },
    {
        dictTitle:'不合理行为投诉',
        dictValue:4
    },
]

export const wxll = [
    {
        dictTitle:'常规设备',
        dictValue:1
    },
    {
        dictTitle:'系统硬件',
        dictValue:2
    },
    {
        dictTitle:'应用软件',
        dictValue:3
    },
]
export const wxjd = [
    {
        dictTitle:'维修中',
        dictValue:1
    },
    {
        dictTitle:'已维修',
        dictValue:2
    },
]

export const xb = [
    {
        dictTitle:'男',
        dictValue:1
    },
    {
        dictTitle:'女',
        dictValue:2
    },
]

export const yesOrNo = [
    {
        dictTitle:'是',
        dictValue:1
    },
    {
        dictTitle:'否',
        dictValue:0
    },
]
