import Vue from 'vue'
import { EventsEnum } from '@/services/eventBus'

export default {
  namespaced: true,

  state:{
    menus:null
  },
  mutations:{
    setMenus(state,value){
      state.menus = value
      Vue.prototype.$eventBus.$emit(EventsEnum.TASK_HIST_ASSIST_TASK)
    }
  }

}
