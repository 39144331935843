<template>
  <div class="bg">
    <img src="@/assets/images/home/header-bg.png" alt="img" class="img" />

    <div class="title" v-if="routePath == '/home'">
      福州{{ scene.sceneData.areaInfo.areaName || "安防" }}监控一览图
    </div>
    <div class="title" v-if="routePath == '/home-2d'">
      福州{{ sceneAMap.sceneData.areaInfo.areaName || "安防" }}监控一览图
    </div>

    <div class="time-box">
      <div class="menu-bg" @click="showMenu = !showMenu" :class="{ active: showMenu }">
        菜单
      </div>
      <div class="time">{{ autoTimer }}</div>
      <img
        src="@/assets/images/dx-logo-2.png"
        v-if="logo === '中国电信'"
        alt="img"
        class="dx-logo"
      />
      <img
        src="@/assets/images/hua-logo.png"
        v-if="logo === '华榕保安'"
        alt="img"
        class="hua-logo"
      />
    </div>
    <div class="right-box">
      <iframe
        width="200"
        style="transform: scale(0.8) translateX(22px)"
        scrolling="no"
        height="50"
        frameborder="0"
        allowtransparency="true"
        src="https://i.tianqi.com?c=code&id=94&color=%23FFFFFF&icon=1&py=fuzhou&site=12"
      ></iframe>
      <div class="area" @click="selectPark">福州</div>
      <div class="use-model" v-if="routePath == '/home'">
        全景 <el-switch v-model="isAllScene" @change="changeScene"></el-switch>
      </div>
      <i
        class="el-icon-setting setting-icon"
        v-if="routePath == '/home'"
        @click="handleCesiumSetting"
      />
      <!--      <div v-if="routePath == '/home'">-->
      <!--        <img src="@/assets/images/dx-logo-2.png" alt="img" class="dx-logo" />-->
      <!--      </div>-->
      <div class="time" v-if="routePath != '/home'">欢迎{{ userInfo.username }}登录</div>
    </div>
    <el-collapse-transition>
      <div class="menu-box" v-show="showMenu">
        <el-scrollbar class="menu-container">
          <left-menu />
        </el-scrollbar>
      </div>
    </el-collapse-transition>
    <cesium-setting ref="cesiumSettingRef" />
  </div>
</template>

<script>
import MainMixins from "@/mixins/main-mixins";
import { mapGetters } from "vuex";
import LeftMenu from "@/components/menu/left-menu";
import CesiumSetting from "@/components/cesium-map/widgets/setting.vue";
import { CESIUM_LOCAL_KEY, CesiumParams } from "@/components/cesium-map/config";

export default {
  name: "home-header",
  components: { CesiumSetting, LeftMenu },
  mixins: [MainMixins],
  data() {
    return {
      time: new Date(),
      autoTimer: "",
      autoTimerInter: "",
      userInfo: {},
      showMenu: false,
      logo: CesiumParams.logo,
      isAllScene: localStorage.getItem("3dtilesType") == 2,
    };
  },
  computed: {
    ...mapGetters(["scene", "sceneAMap"]),
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    changeScene(type) {
      if (type) {
        localStorage.setItem("3dtilesType", 2);
      } else {
        localStorage.setItem("3dtilesType", 1);
      }
      window.location.reload();
    },
    setTime() {
      this.time = new Date();
      setTimeout(this.setTime, (60 - this.time.getSeconds()) * 1000);
      this.autoTimer = this.dateFormatter(new Date(), "yyyy-MM-dd hh:mm:ss");
      this.autoTimerInter = setInterval(() => {
        this.autoTimer = this.dateFormatter(new Date(), "yyyy-MM-dd hh:mm:ss");
      }, 1000);
    },
    selectPark() {
      // 切换成选择区域场景
      if (this.routePath === "/home") {
        this.$store.commit("scene/setScene", "selectPark");
      }
      if (this.routePath === "/home-2d") {
        this.$store.commit("sceneAMap/setScene", "selectPark");
      }
    },
    handleCesiumSetting() {
      this.$refs.cesiumSettingRef.show();
    },
  },
  mounted() {
    let params = window.localStorage.getItem(CESIUM_LOCAL_KEY);
    if (params) {
      try {
        params = JSON.parse(params);
        this.logo = params.logo || CesiumParams.logo;
      } catch (e) {}
    }
    this.setTime();
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  },
  beforeDestroy() {
    clearInterval(this.autoTimerInter);
  },
};
</script>

<style scoped lang="less">
.bg {
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 3000;
  position: absolute;
  .menu-box {
    position: fixed;
    top: 76px;
    left: 0;
    width: 195px;
    height: calc(100vh - 76px);
    .menu-container {
      height: 100%;
    }
  }
  .img {
    width: 100%;
    position: relative;
    top: -4px;
  }
  .title {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 15px;
    user-select: none;
    font-family: "微软雅黑";
  }
  .time-box {
    position: absolute;
    top: 10px;
    display: flex;
    justify-content: center;
    left: 20px;
    .menu-bg {
      background-image: url("~@/assets/images/monitor/menu-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 78px;
      height: 34.5px;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      user-select: none;
      cursor: pointer;
      margin-right: 10px;
      transition: color 0.3s;
      &:hover {
        color: #01cff7;
        &:before {
          border-color: #01cff7 transparent transparent;
        }
      }
      &.active {
        color: #01cff7;
        &:before {
          border-color: #01cff7 transparent transparent;
          transform: rotate(180deg);
          top: -3px;
        }
      }
      &:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border: 6px solid;
        position: relative;
        top: 3px;
        transition: border-color 0.3s;
        margin-right: 6px;
        border-color: #ffffff transparent transparent;
      }
    }
  }
  .time {
    height: 21.5px;
    margin-right: 10px;
    color: #ffffff;
    background-image: url("~@/assets/images/home/header-time-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 6px 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .right-box {
    position: absolute;
    right: 10px;
    top: 3px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .area {
      text-shadow: 0 0 13px rgba(26, 125, 242, 0.9100000262260437);
      margin-right: 20px;
      font-size: 24px;
      cursor: pointer;
    }
    .weather {
      color: #45a2ff;
      text-shadow: 0 0 13px rgba(26, 125, 242, 0.9100000262260437);
      margin-right: 10px;
      font-size: 18px;
    }
    .temperature {
      text-shadow: 0 0 13px rgba(26, 125, 242, 0.9100000262260437);
      color: #45a2ff;
      font-size: 24px;
      margin-right: 16px;
    }

    .use-model {
      margin-right: 20px;
    }
    .setting-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }
}
.dx-logo {
  width: 155px;
  display: block;
  transform: scale(1.5) translateX(19px);
}
.hua-logo {
  width: 34px;
  display: block;
  transform: scale(1.5) translateX(11px);
}
</style>
