
import http from '@/libs/http';

// 区域信息
export function areaInfo(data){
    return http.post("/area-info/current",data)
}

// 区域列表
export function areaList(data){
    return http.post("/area-info/list",data)
}

// 告警信息
export function alarmInfoList(data){
    return http.post('/alarm-info/list',data)
}

// 告警信息全部
export function alarmInfoListAll(data){
    return http.post('/alarm-info/listAll',data)
}

//当天保安考勤列表
export function securityStaffInfoList(data){
    return http.post('/schedule-manager/allWatchKeeper',data)
}

// 保安信息位置全部
export function securityStaffInfoListAll(data){
    return http.post('/security-staff-site/listAll',data)
}


// 设备统计
export function statisticsDevice(data){
    return http.post('/statistics/statisticsDevice',data)
}

// 疫情防控统计
export function epidemicInfoCount(data){
    return http.post('/statistics/epidemic',data)
}

// 疫情柱状图统计
export function epidemicGraph(data){
    return http.post('/statistics/epidemicGraph',data)
}


// 疫情柱状图统计
export function visitorsFlowRateGraph(data){
    return http.post('/statistics/visitorsFlowRateGraph',data)
}


// 疫情防控列表
export function epidemicInfoList(data){
    return http.post('/visitors-flow-rate/list',data)
}


// 园区人流量（今日）
export function visitorsFlowRateCount(data){
    return http.post('/statistics/visitorsFlowRate',data)
}

//白班夜班在岗人数
export function securityStaffInfoCountOnGuard(data){
    return http.post('/statistics/onGuard',data)
}

// 设备列表全部
export function deviceInfoListAll(data){
    return http.post('/device-info/listAll',data)
}

// 设备列表
export function deviceInfoList(data){
    return http.post('/device-info/list',data)
}

// 摄像头列表
export function deviceInfoImportDevice(data){
    return http.post('/device-info/importDevice',data)
}

// 园区人流量定时器开启
export function startSchedule(data){
    return http.get('/visitors-flow-rate/startSchedule',data)
}

// 园区人流量定时器关闭
export function stopSchedule(data){
    return http.get('/visitors-flow-rate/stopSchedule',data)
}

// 任务分配
export function assignTask(data){
    return http.post('/security-staff-task/assignTask',data)
}

// 创建协助任务
export function createAssistTask(data){
    return http.post('/security-staff-task/createAssistTask',data)
}

// 误报
export function algorithmCheck(data){
    return http.post('/alarm-info/algorithmCheck',data)
}

// 查询路线点接口
export function getInfoByClassesId(id){
    return http.get(`/patrol-guidelines/infoByScheduleId/${id}`)
}

// 排班日历信息
export function  listMonth(data){
    return http.post("/user-schedule-alone/listMonth",data)
}

// 获取排班详情
export function  aloneInfo(data){
    return http.post("/user-schedule-alone/info",data)
}

// 删除排班
export function  aloneDelete(data){
    return http.post("/user-schedule-alone/delete",data)
}
// 新增排班
export function  aloneSave(data){
    return http.post("/user-schedule-alone/save",data)
}
// 新增排班
export function  aloneUpdate(data){
    return http.post("/user-schedule-alone/update",data)
}
// 新增排班
export function  flowrateList(data){
    return http.post("/visitors-flowrate-info/list",data)
}

// 一键打卡上班
export function  oneClockIn(data){
    return http.post("/attendance-clock-in/oneClockIn",data)
}

// 一键打卡下班
export function  oneClockOut(data){
    return http.post("/attendance-clock-in/oneClockOut",data)
}

