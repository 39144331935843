<template functional>
<div class="sidebar-item">
  <icon-svg class="icon-img"  :name="props.icon"  v-if="props.icon" />
  <span slot="title">{{ props.title }}</span>
</div>
</template>
<style lang="less">
.el-menu-item,
.el-submenu__title {
  .sidebar-item {
    .icon-img {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;
    }

    /deep/ .svg-icon {
      width: 14px;
      height: 14px;
      color: #ccc;
    }

    span {
      margin: 0;
      color: #ccc;
    }

    .svg-icon + span,
    .icon-img + span {
      margin: 0 0 0 10px;
    }
  }
  &:hover{
    .icon-img{
      color: #ffffff;
    }
  }
  &.is-active,

  &.is-opened {
    .sidebar-item {
      .icon-img {
        color: inherit;
      }
      span {
        color: #fff;
      }
    }
  }
}
</style>
<script>
export default {
  name: 'Item',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
