import http from "@/libs/http";
import * as staticDict from "@/constant/dict";

// 获取字典信息 带缓存
export function getDict(code, type = "array") {
  function toObject(dict = []) {
    let dictObject = {};
    dict.forEach((res) => {
      dictObject[res.dictValue] = res.dictTitle;
    });
    return dictObject;
  }

  if (staticDict[code]) {
    if (type === "object") return Promise.resolve(toObject(staticDict[code]));
    return Promise.resolve(staticDict[code]);
  } else {
    return Promise.reject("未查询到相关字典");
  }
}

// 文件上传
export function uploadFile(data) {
  return http.post("/system/upload", data);
}
// 告警图片上传
export function uploadImgFile(data) {
  return http.post("/alarm-info/uploadPic", data);
}

// 导航菜单
export function menuNav() {
  return http.get("/sys-menu/nav");
}

// 巡逻地址字典树
export function patrolAddressDictTree() {
  return http.get("/patrol-address/dictTree");
}

// 选择系统人员
export function sysUserList() {
  return http.post("/sys-user/list", {
    userType: "5001",
    pageSize: 9999,
    pageIndex: 1,
  });
}

// 选择岗亭
export function sentryBoxSelectList() {
  return http.post("/sentry-box/list", { pageSize: 9999, pageIndex: 1 });
}

// 选择班次
export function classesSelectList() {
  return http.post("/classes-manager/list", { pageSize: 9999, pageIndex: 1 });
}

// 选择路线
export function patrolGuidelinesList() {
  return http.post("/patrol-guidelines/list", { pageSize: 9999, pageIndex: 1 });
}

// 巡逻路线列表
export function patrolRouteList() {
  return http.post("/patrol-route/list", { pageSize: 9999, pageIndex: 1 });
}

// 获取人员信息
export function getCurrentUser() {
  return http.get("/sys-user/getCurrentUser");
}

// 登录
export function thirdLogin(data) {
  return http.post("/sys-login/thirdAnbaoLogin", data);
}

// 选择本部门人员
export function thisDepartmentUserList(applyId) {
  return http.get(`/sys-user/thisDepartmentUserList/${applyId}`);
}

// 日报导出含地图
export function dailyExport(data) {
  return http.file("/daily-newspaper-manager/dailyExportMap", data);
}

// 获取监控轮询
export function getVideoCarousel(code) {
  return http.get("/sys-params/getByCode/" + code);
}
