import router from '@/router'
import staticRouters from '@/router/routes'
import { menuNav } from '@/services/common'
import store from '@/store'
import Vue from 'vue'
import LayoutFullScreen from '@/layout/full-screen'
import LayoutMain from '@/layout/main'

const layouts = {
  LayoutFullScreen,
  LayoutMain
}

export async function beforeEach(to, from, next) {
  document.title = `智慧安保-${to?.meta?.title || to.path}`
  next();
}


// 路由权限控制
export async function authEach(to,from,next){
  // 过滤静态添加路由
  if(staticRouters.filter(res=>res.path === to.path).length) return next();


  // 添加动态(菜单)路由
  // 1. 已经获取到了菜单直接下一步
  if(store.state.menu.menus?.length)  return next()

  //否则请求接口获取菜单
  let data = await menuNav().catch(err=>{
    window.sessionStorage.clear();
    console.log(`%c${err} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
    router.replace("/login")
    throw new Error(err)
  })

  if(!data || data.code !== 200) {
    window.sessionStorage.clear();
    Vue.prototype.$message.error("获取菜单失败!请联系管理员！")
    return next({path:"/login"})
  }
  if(data.menuList.length === 0 ){
    window.sessionStorage.clear();
    Vue.prototype.$message.error("当前用户未配置菜单!请联系管理员!")
    return next({path:"/login"})
  }

  let menus = fnAddDynamicMenuRoutes(data.menuList,0)

  let routes = fnAddDynamicRoutes(data.menuList)

  try{
    routes.push( {
      path: '/',
      meta: {
        title:"首页"
      },
      hidden: true,
      redirect: () => {
        return data.menuList.filter(res=>res.url !== '')[0].url
      }
    })

    routes.push({
      meta: {
        title:"404"
      },
      hidden: true,
      path: "/:pathMatch(.*)*",
      redirect: '/404'
    })
  }catch(e){
    Vue.prototype.$message.error("获取菜单失败!请联系管理员！")
    return next({path:"/login"})
  }

  router.addRoutes(routes)
  store.commit("menu/setMenus",menus)
  next({path: to.path})
}


/**
 * 生成动态路由
 * @param {*} routerList 路由列表
 */
function fnAddDynamicRoutes (routerList = [] ) {
  let routes = []
  let layoutRoutes = {}

  for(let i =0; i < routerList.length; i++) {
    let routerItem = routerList[i]

    // 设置默认layout
    if(!routerItem.layout){
      routerItem.layout = "LayoutMain"
    }

    // 如果layout 不存在不处理
    if(layouts[routerItem.layout]){

      if(!layoutRoutes[routerItem.layout]){
        layoutRoutes[routerItem.layout] = {
          name:routerItem.layout,
          path: routerItem.layout,
          component: layouts[routerItem.layout],
          children:[]
        }
      }

      // 菜单的
      if(routerItem.type === 1){
        layoutRoutes[routerItem.layout].children.push({
          path: routerItem.url || `${routerItem.layout}${i}`,
          component: ()=>import(`@/views${routerItem.component}`) || null,
          name: routerItem.name,
          meta: {
            title: routerItem.name,
            icon: routerItem.icon
          },
        })
      }
    }
  }

  for(let i in layoutRoutes){
    routes.push(layoutRoutes[i])
  }

  return routes;
}


/**
 * 生成动态菜单
 * @param {*} menuList 菜单列表
 * @param pid
 */
function fnAddDynamicMenuRoutes (menuList = [],pid ) {
  let route = []
  for(let i =0;i<menuList.length; i++){
    let menuItem = menuList[i]
    if(menuItem.parentId === pid){
      let params = {
        path: menuItem.url || `${menuItem.layout}${i}`,
        name: menuItem.name,
        meta: {
          title: menuItem.name,
          icon: menuItem.icon
        },
      }
      params['children'] = fnAddDynamicMenuRoutes(menuList,menuItem.id)
      route.push(params)
    }
  }
  return route;
}
