<template>
  <component v-bind="linkProps(to)">
    <slot />
  </component>
</template>
<script>

export default {
  props: {
    to: {
      type: String,
      default: ''
    },
    isLink: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    linkProps (url) {
      if (this.isLink) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        }
      } else {
        return {
          is: 'router-link',
          to: url
        }
      }
    }
  }
}
</script>
<style >
a{
  text-decoration: none;
}
</style>
