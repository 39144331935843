export default {
    namespaced: true,

    state:{
        workType:"1",
        treeData:[
            {
                id:'all',
                label:'开启全部图层',
                children:[
                    {
                        id:'ry',
                        label:'人员点位',
                        icon:'setting1.png'
                    },
                    {
                        id:'gt',
                        type:'1005',
                        label:'岗亭点位',
                        icon:'setting2.png'
                    },
                    {
                        id:'dy',
                        label:'钓鱼区域',
                        type:'1002',
                        icon:'setting3.png'
                    },
                    {
                        id:'sy',
                        label:'危险水域',
                        type:'1001',
                        icon:'setting4.png'
                    },
                    {
                        id:'st',
                        label:'山体',
                        type:'1003',
                        icon:'setting5.png'
                    },
                    {
                        id:'jq',
                        label:'告警',
                        icon:'setting6.png'
                    }
                ]
            }
        ],
        layerCheckNode:[],
        leftPanel:true,
        alarmClose:false,
        rightPanel:true,
        areaInfo:{
            id:1
        },
        alarmInfo:{},
        people:[]
    },

    mutations:{
        openAlarm(state,value){
            state.alarmClose = value
        },
        setWorkType(state,value){
            state.workType = value
        },
        setPeople(state,value){
            state.people = value;
        },
        setLayerCheckNode(state,value){
            state.layerCheckNode = value
        },
        setAlarmInfo(state,value){
            state.alarmInfo = value
        },
        showLeftPanel(state){
            state.leftPanel = true
        },
        closeLeftPanel(state){
            state.leftPanel = false
        },
        showRightPanel(state){
            state.rightPanel = true
        },
        closeRightPanel(state){
            state.rightPanel = false
        },
        setAreaInfo(state,data){
            state.areaInfo = data;
        }
    }

}
