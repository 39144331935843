<template>
  <div class="pagination" :style="{
    textAlign:align
  }">
    <PaginationElement
        :layout="layout"
        @current-change="handleSearch"
        @size-change="handleSizeChange"
        :current-page.sync="_jcurrentPage"
        :page-size.sync="_pageSize"
        :page-sizes="pageSizes"
        ref="pagination"
        :total="total"
    >
    </PaginationElement>
  </div>
</template>

<script>
import Vue from 'vue'
import { Pagination } from 'element-ui'
Vue.component('PaginationElement',Pagination)
export default {
  name:"el-pagination",
  props:{
    pageSize:{type:Number,default:10},
    currentPage:{type:Number,default:1},
    total:{type:Number,default:0},
    pageSizes:{type:Array,default(){return [10,20,30,40,50,100]}},
    layout:{
      type:String,
      default:"prev, pager, next"
    },
    align:{
      type:String,
      default:"center"
    }
  },
  data(){
    return {
      jcurrentPage:1,
    }
  },
  computed:{
    _jcurrentPage:{
      get(){
        return this.currentPage
      },
      set(val){
        this.$emit('update:currentPage',val)
      }
    },
    _pageSize:{
      get(){
        return this.pageSize
      },
      set(val){
        this.$emit('update:pageSize',val)
      }
    },
  },
  methods:{
    handleSearch(e){
      this.$emit('current-change',e)
    },
    handleSizeChange(e){
      this.$emit("size-change",e)
    }
  },
}
</script>
<style scoped lang="less">
.pagination{
  text-align: center;
}
</style>
