<template>
  <section class="layout-container">
    <aside class="menu-container" >
      <layout-logo/>
      <el-scrollbar class="menu-content">
        <left-menu/>
      </el-scrollbar>
    </aside>
    <section class="view">
      <layout-header/>
      <router-view v-slot="{Component}">
        <div class="-enter-x">
          <component :is="Component" />
        </div>
      </router-view>
    </section>
  </section>
</template>

<script>
import LeftMenu from '@/components/menu/left-menu'
import LayoutLogo from '@/layout/components/layout-logo'
import LayoutHeader from '@/layout/components/layout-header'
export default {
  name: 'layout-full-screen',
  components: { LayoutHeader, LayoutLogo, LeftMenu },
  data(){
    return {
    }
  },
  methods:{
    toggleVisibleMenu() {
      this.$store.commit("layout/toggleVisibleMenu",!this.$store.state.layout.visibleMenu)
    },
    closeMenu(){
      if(this.$store.state.layout.visibleMenu){
        this.$store.commit("layout/toggleVisibleMenu",false)
      }
    },
    logout(){
      window.close()
    }
  }
}
</script>

<style scoped lang="less">
@headerHeight: 40px;

.layout-container {
  height: 100vh;
  display: flex;

  .menu-container {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--el-menu-border-color);
    background-color: var(--soar-menu-bg);
    .menu-content {
      flex: 1;
    }
  }

  .view {
    height: 100vh;
    flex-direction: column;
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: var(--el-bg-color-page);

    .content {
      height: 100%;
      padding: 12px;

    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
