import {defaultRenderCell, treeCellPrefix} from "@/components/element-ui/ElTableColumn/config";
import {dictTranslate} from "@/libs/common";

export default {
    props:{
        // 新增的props
        add: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        info: {
            type: Boolean,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
        formType: String,
        dictData:  {
            type: Array,
            default() {
                return []
            }
        },
        formatter: {
            type:Function,
            default(item,column){
                let key =  column.property
                return item[key]
            }
        },
        //使用dictData格式化
        useDictFormatter:Boolean,
        defaultValue:{
            type:[String,Number,Array]
        },
        rules:[Array,Object],
        required: {
            type: Boolean,
            default: undefined
        },
        pickerConfig:Object,
        formOptions:Object,
    },
    methods:{
        setColumnRenders(column) {
            // renderHeader 属性不推荐使用。
            if (this.renderHeader) {
                console.warn('[Element Warn][TableColumn]Comparing to render-header, scoped-slot header is easier to use. We recommend users to use scoped-slot header.');
            } else if (column.type !== 'selection') {
                column.renderHeader = (h, scope) => {
                    const renderHeader = this.$scopedSlots.header;
                    return renderHeader ? renderHeader(scope) : column.label;
                };
            }

            let originRenderCell = column.renderCell;
            // TODO: 这里的实现调整
            if (column.type === 'expand') {
                // 对于展开行，renderCell 不允许配置的。在上一步中已经设置过，这里需要简单封装一下。
                column.renderCell = (h, data) => (<div class="cell">
                    { originRenderCell(h, data) }
                </div>);
                this.owner.renderExpanded = (h, data) => {
                    return this.$scopedSlots.default
                        ? this.$scopedSlots.default(data)
                        : this.$slots.default;
                };
            } else {
                originRenderCell = originRenderCell || defaultRenderCell;
                // 对 renderCell 进行包装
                column.renderCell = (h, data) => {
                    // 该部分新增
                    if(this.useDictFormatter && data.column){
                        data.column.formatter = (row, column, value, $index)=>{
                            return dictTranslate(value, this.dictData)
                        }
                    }
                    // 该部分新增结束

                    let children = null;
                    if (this.$scopedSlots.default) {
                        children = this.$scopedSlots.default(data);
                    } else {
                        children = originRenderCell(h, data);
                    }


                    const prefix = treeCellPrefix(h, data);
                    const props = {
                        class: 'cell',
                        style: {},
                    };
                    if (column.showOverflowTooltip) {
                        props.class += ' el-tooltip';
                        props.style = {width: (data.column.realWidth || data.column.width) - 1 + 'px'};
                    }
                    return (<div { ...props }>
                        { prefix }
                        { children }
                    </div>);
                };
            }
            return column;
        },

    }
}
