import AmapMercatorTilingScheme from './lib/AmapMercatorTilingScheme'
import { initCesiumParams, CesiumParams } from '@/components/cesium-map/config'



export let viewer;


//初始化viewer
function initViewer(el) {
  // 初始化参数
  initCesiumParams()
  viewer = new Cesium.Viewer(el, {
    infoBox: false,
    selectionIndicator: false,
    navigation: false,
    animation: false,
    timeline: false,
    baseLayerPicker: false,
    geocoder: false,
    homeButton: false,
    sceneModePicker: false,
    navigationHelpButton: false,
    requestRenderMode: CesiumParams.requestRenderMode,
    maximumRenderTimeChange: Infinity,
    targetFrameRate:CesiumParams.targetFrameRate,
    // terrainProvider: Cesium.createWorldTerrain({
    //     requestWaterMask: false,
    //     requestVertexNormals: false
    // }),
    imageryProvider: new Cesium.UrlTemplateImageryProvider({
      url: "https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
      maximumLevel: 18,
      minimumLevel: 2,
      tilingScheme: new AmapMercatorTilingScheme(),
    })
  });


  // CesiumGui.show()

  // viewer.scene.screenSpaceCameraController.minimumZoomDistance = 100;
  // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 3500;
  // viewer.scene.debugShowFramesPerSecond = true
  viewer.scene.animation = true
  viewer.scene.globe.show = true
  viewer.scene.globe.baseColor = Cesium.Color.fromCssColorString('#090C22');
  viewer.scene.sun.show = CesiumParams.sun;
  viewer.scene.moon.show = CesiumParams.moon;
  viewer.scene.globe.depthTestAgainstTerrain = CesiumParams.depthTestAgainstTerrain;
  viewer.scene.undergroundMode = CesiumParams.undergroundMode;
  viewer.scene.skyAtmosphere.show = CesiumParams.skyAtmosphere;
  viewer.scene.globe.showGroundAtmosphere = CesiumParams.showGroundAtmosphere
  viewer.scene.globe.enableLighting = CesiumParams.enableLighting
  viewer.scene.fog.enabled = CesiumParams.fog
  viewer.cesiumWidget.creditContainer.style.display = "none";

  // viewer.scene.imageryLayers.removeAll(true)
  //是否开启抗锯齿
  viewer.scene.postProcessStages.fxaa.enabled = CesiumParams.fxaa;

  // 适配高清方案
  if(Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
    viewer.resolutionScale = CesiumParams.pixelRatio
  }

  // 倾斜视图 鼠标左键旋转
  viewer.scene.screenSpaceCameraController.tiltEventTypes = [ Cesium.CameraEventType.RIGHT_DRAG ];

  // 缩放设置 重新设置缩放成员
  viewer.scene.screenSpaceCameraController.zoomEventTypes = [Cesium.CameraEventType.MIDDLE_DRAG, Cesium.CameraEventType.WHEEL, Cesium.CameraEventType.PINCH];

  // 偏斜平移
  //viewer.scene.screenSpaceCameraController.lookEventTypes = [ Cesium.CameraEventType.RIGHT_DRAG]

  // 平移 添加鼠标右键  鼠标右键平移
  viewer.scene.screenSpaceCameraController.rotateEventTypes = [Cesium.CameraEventType.LEFT_DRAG] 

  let boundingSphere = new Cesium.BoundingSphere(Cesium.Cartesian3.fromDegrees(119.2841072, 26.09412942, -7.385333898), 600.4415708);
  // viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function(cmd) {
  //   viewer.camera.flyToBoundingSphere(boundingSphere);
  //   cmd.cancel = true;
  // });

  viewer.camera.flyToBoundingSphere(boundingSphere, { duration: 0 });

  if(CesiumParams.requestRenderMode){
    let speed = 0
    viewer.scene.postUpdate.addEventListener(()=>{
      speed++
      if(speed === 4){
        viewer.scene.requestRender()
        speed = 0
      }
    })
  }

  // window.lnglat = [];
  // let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
  //  handler.setInputAction((movement)=>{
  //    let cartesian = viewer.camera.pickEllipsoid(movement.position);
  //    let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
  //    let lng = Cesium.Math.toDegrees(cartographic.longitude); // 经度
  //    let lat = Cesium.Math.toDegrees(cartographic.latitude); // 纬度
  //    let alt = cartographic.height; // 高度，椭球面height永远等于0
  //    window.lnglat.push(...[lng, lat, 0])
  //    console.log(lng,lat,0)
  //  },Cesium.ScreenSpaceEventType.LEFT_CLICK)

  // var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
  //  handler.setInputAction(event => {
  //    // 屏幕坐标转为空间坐标
  //    let cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
  //    if (cartesian == undefined) {
  //      console.log('没有获取到坐标')
  //    } else {
  //      // 空间坐标转世界坐标(弧度)
  //      let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
  //      // 弧度转为角度（经纬度）
  //      let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
  //      let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
  //      console.log('经纬度是：', { x: lon, y: lat });
  //    }
  //  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

}


export function destroy() {
  viewer.entities.removeAll();
  viewer.imageryLayers.removeAll(true);
  viewer.destroy();
  viewer._element.remove();
}

export  function init(el) {
  initViewer(el);
}






