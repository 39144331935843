import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/components/element-ui";
import "@/components/auto-install";
import "@/directive"; //注册指令
import "@/mixins"; //注册全局mixins
import "@/main.less";
import "@/services/eventBus.js"; //事件总线
import { getQueryString } from "@/libs/common";
import { thirdLogin } from "@/services/common";

// 1 项目开启 真实数据  0项目关闭 用mock数据
// getProjectConfig()
//   .then((res) => {
//     Vue.prototype.$isMockData = res.data == 1 ? false : true;
//     loadMock();
//   })
//   .catch((err) => {
//     Vue.prototype.$isMockData = true;
//     loadMock();
//   });

// function loadMock() {
//   // Vue.prototype.$isMockData = false;
//   if (Vue.prototype.$isMockData) {
//     require("./mock"); //引入 mock 入口文件
//   }
// }

// 监听 路由信息
let jwt = getQueryString("jwt")

function render(){
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app")

}


if(jwt){
    thirdLogin({
        accessToken:jwt
    }).then(res=>{
        window.sessionStorage.setItem("token",res.token)
        window.history.replaceState({
            path:location.pathname + location.hash
        },'',location.pathname + location.hash)
        render()
    }).catch(err=>{
        render()
    })
}else{
    render()
}
