/**
 * 饿了么 ui 组件
 */
import Vue from 'vue'
import {
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    DatePicker,
    TimeSelect,
    TimePicker,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Tree,
    Alert,
    Slider,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Cascader,
    Calendar,
    Popover,
    Table,
    ColorPicker,
    Link,
    Divider,
    Image,
    Loading,
    Message,
    Timeline,
  TimelineItem,
    MessageBox,
  Drawer,
    Notification,
} from 'element-ui'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import ScrollBar from 'element-ui/lib/scrollbar'
import Pagination from './ElPagination/install'
import TableColumn from './ElTableColumn/install'

Dialog.props.closeOnClickModal.default = false

// 组件
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Tooltip)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Alert)
Vue.use(Slider)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Badge)
Vue.use(Card)
Vue.use(Rate)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Cascader)
Vue.use(ColorPicker)
Vue.use(Link)
Vue.use(Calendar)
Vue.use(Popover)
Vue.use(ScrollBar)
Vue.use(Divider)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Drawer)
Vue.use(Image)
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component(ElImageViewer.name,ElImageViewer)
// 指令
Vue.use(Loading.directive)


// 重写 message 吐司 重复的不显示两遍。隐藏第上一个
export function resetMsg (option) {
    let dom = document.getElementsByClassName('el-message')
    let canShow = true;
    dom.forEach((dom) => {
        let optionMessage =  option.message
        if(option.dangerouslyUseHTMLString === true){
            let div = document.createElement("div")
            div.innerHTML = option.message
            optionMessage = div.innerText
        }
        if(dom.innerText.replaceAll(" ","") === optionMessage.replaceAll(" ","")){
            canShow = false
        }
    })
    if (dom.length === 0 || canShow) {
        Message(option)
    }
}

["error", "success", "info", "warning"].forEach(type => {
    resetMsg[type] = options => {
        if (typeof options === "string") {
            options = {
                message: options
            }
        }
        options.type = type
        return resetMsg(options)
    }
})



Vue.prototype.$message = Message
Vue.prototype.$confirm = (e, r, t) => {
    return MessageBox.confirm(e, r, {
        ...t,
        closeOnClickModal: false
    })
}
Vue.prototype.$notify = Notification
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
