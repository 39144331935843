import Vue from 'vue'
import { debounce } from '@/libs/common'
import { eventBus } from '@/services/eventBus'

export const socketEvent = {

  /// 历史
  HIS_SITE:"HIS_SITE",

  // 历史位置
  HIS_CURRENT_SITE:"HIS_CURRENT_SITE",

  /// 通用
  GENERAL:"GENERAL",

  /// 当前位置
  CURRENT_SITE:"CURRENT_SITE",

  /// 查询信息
  SITE_QUERY:"SITE_QUERY",

  /// 人员确认
  PEOPLE_CHECK:"PEOPLE_CHECK",

  // 任务完成
  FINISH_TASK: "FINISH_TASK",

  // 任务协助
  ASSIST_TASK: "ASSIST_TASK",

  // 历史协助
  HIS_ASSIST_TASK: "HIS_ASSIST_TASK",

  // 巡逻排班
  PATROL_CLOCK_IN_TASK: "PATROL_CLOCK_IN_TASK",

  // 任务列表
  LIST_TASK: "LIST_TASK",

  // 人员信息
  PATROL_SCHEDULE_TASK: "PATROL_SCHEDULE_TASK",

  // 人员巡逻打卡信息
  PATROL_SCHEDULE_CLOCK_IN_TASK: "PATROL_SCHEDULE_CLOCK_IN_TASK",

  // 获取人员信息
  DESIGNATE_USER: "DESIGNATE_USER",

  // 更新人员定位信息
  UPDATE_SITE: "UPDATE_SITE",

  // 历史记录
  HISTORY_SITE: "HISTORY_SITE",

  // 任务状态
  TASK_STATUS_CHANGE: 'TASK_STATUS_CHANGE',

  // 获取统计信息
  DATA_STATISTIC: 'DATA_STATISTIC',
}

class SocketFactory {

  /**
   * url
   */
  url = "";

  /**
   * 初始化
   */
  init (){
    if(this._reLinkCount >= 100){
      return Vue.prototype.$message.error("与服务断开连接。")
    }
    if(this._reLinkCount >= 1){
      Vue.prototype.$message.warning(`与服务器连接发生断开，正在尝试第${this._reLinkCount}次重连。`)
    }
    if(!this.url) return console.error("socket链接地址为空")
    if("WebSocket" in window) {
      this._channel = new WebSocket(this.url)
      this.listenInit();
    }else{
      Vue.prototype.$message.error("当前浏览器不支持socket通信，请更换或升级浏览器!")
    }
  }



  /**
   * 重连次数
   * @type {number}
   * @private
   */
  _reLinkCount = 0;


  /**
   * 通道
   * @type {*}
   */
  _channel = null;


  /**
   * 发送消息
   * @param msg
   */
  send = (msg) =>{
    this._channel.send(JSON.stringify(msg))
    this.resetHeart()
  }

  /**
   * 发生消息并且等待返回
   * 当你需要发送一个消息并且等待返回时，可以使用此方法，提供一次回调
   * @param msg 消息
   * @param timeout 超时时间
   */
  sendAndWait = (msg,timeout= 10000) =>{
    return new Promise((resolve, reject) => {
      function callback (data) {
        eventBus.$off(msg.cmd,callback)
        resolve(data)
      }
      eventBus.$on(msg.cmd, callback)
      this._channel.send(JSON.stringify(msg))
      setTimeout(()=>{
        eventBus.$off(msg.cmd,callback)
        reject(`请求超时-${msg.cmd}`)
      },timeout)
    })
  }

  /**
   * 关闭通信
   */
  close = ()=>{
    this._channel.close()
  }


  /**
   * 处理监听事件
   */
  handleEventListener = (data)=>{
    eventBus.$emit(data.cmd,data.data)
  }

  /**
   * 监听消息
   */
  listenInit = ()=>{
    this._channel.onopen = this.onopen
    this._channel.onerror = this.onerror
    this._channel.onclose = this.onclose
    this._channel.onmessage = this._onmessage
  }

  /**
   * relink
   */
  reLink = debounce(()=>{
      this._reLinkCount++;
      this.init();
  },3000)

  /**
   * 心跳
   */
  resetHeart = ()=>{
    clearInterval(this.t)
    this.t = setInterval(()=>{
      this._channel.send(JSON.stringify({"cmd":"PING"}))
    },40000)
  }
  t = null;
  /**
   * onopen
   */
  onopen (){
    if(this._reLinkCount >= 1){
      Vue.prototype.$message.success("重新连接成功!")
      this._reLinkCount = 0;
    }
    this.resetHeart()
  }

  onerror = () =>{
    this.reLink()
  }

  onclose = ()=>{
    this.reLink()
  }

  _onmessage = (msg)=>{
    let data = JSON.parse(msg.data)
    this.handleEventListener(data)
    this.onmessage(data)
  }

  onmessage = (msg) =>{
    console.log("重写该方法",msg)
  }
}

export  default  SocketFactory;
