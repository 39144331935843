import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import { beforeEach,  authEach } from '@/router/middleware'
Vue.use(VueRouter);

const router = new VueRouter({
  // mode: "history",
  routes,
});

router.beforeEach(authEach);
router.beforeEach(beforeEach);
export default router;
