export const sceneEnum = {
  selectPark: 'selectPark',
  monitor: 'monitor',
  patrol: 'patrol',
  alarm: 'alarm'
}

export default {
  namespaced: true,
  state: {
    map: null,
	mapCenter: [119.29,26.08],
    // 场景
    scene: sceneEnum.selectPark,

    // 场景全局数据
    sceneGlobalData: {

      // 值班人员数据信息
      personnelOnDuty: {
        onGuardCount: 0,
        totalCount: 0,
        expatriateCount: 0,
        patrolCount: 0,
        onSentryCount: 0,
        percentage: 0,
        list: []
      },

      // 值班人员定位信息
      personnelPositionMap: {},

      // 告警统计数据
      alarmCount: {
        // 未结束
        noDoneCount: 0,
        // 未进行
        noStartCount: 0,
        // 进行中
        doingCount: 0,
        // 已结束
        doneCount: 0,
      }
    },

    // 场景信息数据
    sceneData: {

      // 当前场景区域信息
      areaInfo: {},

      // 告警场景告警信息数据
      alarmInfo: {},

      // 告警处理人员数据
      alarmHandingPersonData: [],

      // 巡逻场景数据
      patrolInfo: {},
    },
    // 监控摄像头列表
    sceneAMapCameraList:[],

    // 是否使用3d模型
    useModel: true
  },
  mutations: {
    // 设置地图中心点
    setMapCenter(state, mapCenter) {
      state.mapCenter = mapCenter
    },
    // 设置地图
    setMap(state, map) {
      state.map = map
    },
    // 设置场景
    setScene(state, scene) {
      state.scene = scene
    },
    // 重置场景数据
    resetSceneData(state) {
      state.sceneData = {
        areaInfo: {},
        alarmInfo: {}
      }
      state.scene = sceneEnum.selectPark
    },
    // 设置场景区域信息
    setSceneAreaInfo(state, areaInfo) {
      state.sceneData.areaInfo = areaInfo
    },
    // 设置告警场景告警信息数据
    setSceneAlarmInfo(state, alarmInfo) {
      state.sceneData.alarmInfo = alarmInfo
    },
    // 设置告警处理人员数据
    setSceneAlarmHandingPersonData(state, data) {
      let alarmHandingPersonData = state.sceneData.alarmHandingPersonData
      if (alarmHandingPersonData.find(res => res.userId === data.userId)) {
        state.sceneData.alarmHandingPersonData = alarmHandingPersonData.map(res => {
          if (res.userId === data.userId) {
            res = data
          }
          return res
        })
      } else {
        state.sceneData.alarmHandingPersonData.push(data)
      }
    },
    // 设置告警处理人员位置信息
    setGlobalDataAlarmInfoPersonnelPositionMapForUserId(state, data) {
      state.sceneData.alarmHandingPersonData = state.sceneData.alarmHandingPersonData.map(res => {
        if (res.userId === data.userId) {
          // 将之前的位置信息添加到历史位置信息中
          if (res.history) {
            res.history?.push(res.securityStaffSite)
          } else {
            res.history = [res.securityStaffSite]
          }
          // 更新定位
          res.securityStaffSite = data
        }

        return res
      })

    },
    // 设置告警处理人历史记录
    setSceneAlarmHandingPersonHistoryPosition(state, { userId, history }) {
      if (history && history.length) {
        state.sceneData.alarmHandingPersonData = state.sceneData.alarmHandingPersonData.map(res => {
          if (res.userId === userId) {
            // 之前覆盖全部
            res.history = history
          }
          return res
        })
      }
    },

    // 设置告警统计数据
    setGlobalDataAlarmCount(state, data) {

      state.sceneGlobalData.alarmCount = data
    },

    // 设置巡逻场景数据
    setScenePatrolInfo(state, patrolInfo) {
      state.sceneData.patrolInfo = patrolInfo
    },
    // 设置值班人员信息数据
    setGlobalDataPersonnelOnDutyInfo(state, dutyInfo) {
      state.sceneGlobalData.personnelOnDuty = dutyInfo
    },
    // 设置值班人员信息位置Map
    setGlobalDataPersonnelPositionMap(state, positionMap) {
      state.sceneGlobalData.personnelPositionMap = positionMap
    },
    // 通过userId设置用户位置信息
    setGlobalDataPersonnelPositionMapForUserId(state, record) {
      // 判断是否存在 存在在更新 不存在添加
      if (state.sceneGlobalData.personnelPositionMap[record.userId]) {
        for (let key in state.sceneGlobalData.personnelPositionMap) {
          if (key == record.userId) {
            let newKey = {
              ...state.sceneGlobalData.personnelPositionMap[key],
              longitude:record.securityStaffSite.longitude,
              latitude:record.securityStaffSite.latitude
            }
            state.sceneGlobalData.personnelPositionMap = {
              ...state.sceneGlobalData.personnelPositionMap,
              [key]:newKey
            }
          }
        }
      }
    },
    // 设置摄像头列表数据
    setCameraList(state,list){
      state.sceneAMapCameraList = list || []
    },
  },
  actions: {}

}
