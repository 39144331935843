const getters = {
    layerStatus:state=>state.home.layerStatus,
    menus: state=>state.menu.menus,
    areaInfo: state=>state.home.areaInfo,
    alarmInfo: state=>state.home.alarmInfo,
    peoplePosition: state=>state.taskSocket.peoplePosition,
    scene: state=>state.scene,
    sceneGlobalData: state=>state.scene.sceneGlobalData,
    scenePersonnelOnDuty: state=>state.scene.sceneGlobalData.personnelOnDuty,
    scenePersonnelPositionMap : state=>state.scene.sceneGlobalData.personnelPositionMap,
    sceneAlarmCount: state=>state.scene.sceneGlobalData.alarmCount,
    sceneData: state=>state.scene.sceneData,
    sceneAreaInfo: state=>state.scene.sceneData.areaInfo,
    sceneAlarmInfo: state=>state.scene.sceneData.alarmInfo,
    sceneAlarmHandingPersonData: state=>state.scene.sceneData.alarmHandingPersonData,
    scenePatrolInfo: state=>state.scene.sceneData.patrolInfo,
    sceneCameraList: state=>state.scene.sceneCameraList,
    // sceneAMap
    sceneAMap: state=>state.sceneAMap,
    sceneAMapAreaInfo: state=>state.sceneAMap.sceneData.areaInfo,
    sceneAMapPersonnelOnDuty: state=>state.sceneAMap.sceneGlobalData.personnelOnDuty,
    sceneAMapPatrolInfo: state=>state.sceneAMap.sceneData.patrolInfo,
    sceneAMapPersonnelPositionMap : state=>state.sceneAMap.sceneGlobalData.personnelPositionMap,
    sceneAMapMap: state=>state.sceneAMap.map,
    sceneAMapAlarmCount: state=>state.sceneAMap.sceneGlobalData.alarmCount,
    sceneAMapAlarmInfo: state=>state.sceneAMap.sceneData.alarmInfo,
    sceneAMapAlarmHandingPersonData: state=>state.sceneAMap.sceneData.alarmHandingPersonData,
    sceneAMapCameraList: state=>state.sceneAMap.sceneAMapCameraList,
    getTalkOnlineUser: (state) => state.shuguoTalk.onlineUser, 
};

export default getters;
