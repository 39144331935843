// 表格常用mixins
import { dictTranslate, formatDate } from '@/libs/common'

const mainMixins = {
  methods:{
    // 表格时间格式化
    dateFormatter(value,fmt='yyyy-MM-dd'){
        return formatDate(value,fmt)
      },
    // 表格翻译字典
    dictFormatter(value,dictData){
      return dictTranslate(value,dictData)
    },
  },
  filters:{
    dateFormatter(value,fmt='yyyy-MM-dd'){
      return formatDate(value,fmt)
    },
    // 表格翻译字典
    dictFormatter(value,dictData){
      return dictTranslate(value,dictData)
    },
  }

}

export default mainMixins
