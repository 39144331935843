// 引入 axios
import axios from "axios";
import config from "@/config";
import { logout } from "@/views/common/services/login";
import router from "@/router";
let http = axios.create(
  Object.assign(config.axios, {
    responseType: "json",
    headers: { "Content-Type": "application/json;charset-utf-8" },
    timeout: 100000,
  })
);
let timer;
// request拦截器,在请求之前做一些处理
http.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      token: window.sessionStorage.getItem("token"),
    };
    // 12小时未操作自动登出
    clearTimeout(timer);
    // 获取监控接口跳过
    if (config.url.indexOf("/device-info/getVideoUrl") == -1) {
      timer = setTimeout(() => {
        router.replace("/login");
      }, 12 * 3600 * 1000);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器,数据返回后进行一些处理
http.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) { 
      logout();
      return Promise.reject(response.data.message);
    } else {
      if (response.config.file) {
        return response;
      }
      return response.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.file = function (url, data) {
  return http.post(url, data, { file: true, responseType: "blob" });
};

export default http;
