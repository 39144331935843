// 表格常用mixins
import { dictTranslate, formatDate } from '@/libs/common'

const tableMixins = {
  methods:{
    // 表格时间格式化
    dateTableFormatter(fmt){
      return function(row,column,value){
        return formatDate(value || row[column.type],fmt)
      }
    },
    // 表格翻译字典
    dictTableFormatter(dictData){
      return function(item,column,value){
        return dictTranslate(value || item[column.type],dictData)
      }
    }
  }
}

export default tableMixins
