<template>
  <header class="soar-layout-header">

    <div class="icon-container">

      <span class="menu-close-icon" v-if="!$store.state.layout.visibleMenu" @click="toggleVisibleMenu">
          <i class="el-icon-s-fold"></i>
      </span>

      <span class="menu-close-icon" v-if="$store.state.layout.visibleMenu" @click="toggleVisibleMenu">
          <i class="el-icon-s-unfold"></i>
      </span>

    </div>
    <div class="center-container">
      <slot name="center"></slot>
    </div>

    <div class="right-container">
      <a v-if="canDownload" href="http://www.richtalk.cn/api/zhab/richtalk_v4pi_0418.exe" class="download" target="_blank">通讯插件下载</a>
      <div class="exit-btn">
        <span class="logout" @click="handleLogout">
          <i class="el-icon-switch-button"></i>
        </span>
      </div>
    </div>
  </header>
</template>

<script>

import { iDispatchUserLogout } from '@/services/iDispatch'
import { logout } from '@/views/common/services/login'

export default {
  name: 'layout-header',
  data(){
    return {
      canDownload:false
    }
  },
  mounted() {
    let userInfo = window.sessionStorage.getItem("userInfo");
    userInfo = userInfo ? JSON.parse(userInfo) : {}
    this.canDownload = userInfo.superAdmin === '1'
  },
  methods:{
    handleLogout(){
      // await iDispatchUserLogout()
      logout()
    },
    toggleVisibleMenu(){
      this.$store.commit("layout/toggleVisibleMenu")
    }
  }
}
</script>

<style scoped lang="less">
.soar-layout-header {
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  pointer-events: all;
  position: relative;
  justify-content: space-between;
  border-bottom: solid 1px var(--soar-panel-border-color);
  background-color: var(--soar-panel-title-bg-color);

  .icon-container {
    padding: 10px;
    display: flex;
    align-items: center;

    .menu-close-icon {
      color: var(--soar-menu-text-color);
      cursor: pointer;
      user-select: none;
      font-size: 18px;
    }
  }

  .center-container {
    flex: 1;
  }

  .right-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .download{
      font-size: 14px;
      color: #1b7ef2;
      margin-right: 10px;
      cursor: pointer;
    }
    .mt-2 {
      margin-right: 14px;
    }

    .international {
      cursor: pointer;
      font-size: 22px;
      padding: 0 5px;
      color: var(--el-color-primary);
    }

    .logout {
      color: var(--el-menu-text-color);
      cursor: pointer;
      margin-right: 20px;
    }

    .switch {
      margin-right: 20px;
    }

    .dropdown {
      margin-right: 20px;
    }

    .exit-btn {
      display: flex;
      align-items: center;
    }
  }

}
</style>
