// path 使用绝对路径 /开头
export default [
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */'@/views/common/404'),
    name: '404',
    hidden: true,
    meta: { title: '404未找到' }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */'@/views/common/login'),
    name: 'login',
    hidden: true,
    meta: { title: '登录' }
  }
]
