import { socketEvent } from '@/services/socket/socketFactory'
import { getCurrentUser } from '@/services/common'
import Vue from 'vue'
import { TaskSocket } from '@/services/socket'

export default  {
  namespaced: true,
  state: {
    peoplePosition:[],
  },
  mutations:{
    [socketEvent.hasNewTaskExec](state,params){
      state.peoplePosition.push(...params.position)
    },
  },
  actions:{
    /// 初始化socket连接
    socketInit(store){
      Vue.prototype.$taskSocket = new TaskSocket();
    },
    /// 初始化
    async init(store){
      let token = window.sessionStorage.getItem("token")  || window.localStorage.getItem("token")
      if(!token) return
      let userInfo = window.sessionStorage.getItem("userInfo");
      if(userInfo) return store.dispatch("socketInit");
      let user = await getCurrentUser();
      window.sessionStorage.setItem("userInfo",JSON.stringify(user.data))
      return store.dispatch("socketInit");
    },
  }
}
