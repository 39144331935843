<template>
  <div class="left-menu">
      <Sidebar />
  </div>
</template>
<style lang="less">
.left-menu {
    width: 100%;
    height: 100%;
    left: 0;
    top: 40px;
    z-index: 2001;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    background-color: transparent;
    background-size: auto;
    background-repeat: repeat;
    /deep/ .el-loading-mask {
        background: rgba(0, 0, 0, 0.6);
    }
    .scroll{
      height: calc(100% + 15px);
      /deep/ .wrap-class{
        overflow-x: hidden;
      }
    }

  .el-menu--collapse{
    width: 52px;
  }
}
</style>
<script>
import Sidebar from './components/Sidebar'

export default {
  name: 'LeftMenu',
  components: {
    Sidebar
  }
}
</script>
