<template>
  <div style="position: absolute;left: 0;bottom: 0;"
       class="global-map-style">
    <cs-panel v-for="item in contents"
              v-show="!titles[item.id]"
              class="cs-window-container"
              v-draggable="item.dragOptions"
              :id="item.id"
              :key="item.id"
              :title="item.title"
              :icon="item.icon"
              :class="item.class"
              :style="item.style"
              :close="item.close"
              :min="item.min"
              :max="item.max"
              :headId="item.id"
              @mined="mined"
              @maxed="maxed"
              @closed="closed(item)">
      <component :is="item.content"
                 v-bind="{...item.data}"
                 :data="item.data"
                 @mined="mined"
                 @closed="closed(item)"></component>
    </cs-panel>
    <el-row style="z-index: 99;"
            class="cs-window-header">
      <el-col v-for="item in contents"
              :key="item.id"
              v-show="titles[item.id]"
              class="cs-window-header-item"
              @click.native="hide(item.id, false)">
        <span class="text-hidden">{{ item.title }}</span>
        <i class="el-icon-close"
           v-if="item.close"
           @click.stop="closed(item)" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CsPanel from "./CsPanel";

export default {
  name: 'floder',
  components: { CsPanel },
  data () {
    return {
      contents: [],
      titles: {},
      style: {
        transform: `translate(150px, 150px)`
      }
    };
  },
  methods: {



    /**
     * 参数说明：
     *
     {

          id:'',   当前窗口id

          title:'', //窗口标题

          style:'',//样式

          class:'',//样式名

          position:'',//窗口位置

          max:true, //显示最大化按钮

          min:true, //显示最小化按钮

          close:true, //显示关闭按钮

          closeCallback:fn //关闭回调

          dragOptions:{},//拖动参数的配置

          isUpdate:false, //是否开启强制更新 ，会重新开始生命周期

     }
     * @param param { Object }
     * @returns {null}
     */
    openInfoWindow: async function (param) {
      param = Object.assign({
        close: true,
        min: true,
        closeCallback: null,
        isUpdate: false,
        dragOptions: {},
        position: [],
        class: ''
      }, param)


      param.id = param.id ? param.id : "floder" + Math.floor(Math.random() * 1000000);//同一个id,只开启一次
      param.style = param.style ? param.style : {
        transform: `translate(150px, 150px)`
      };

      if (param.position.length === 2) {
        param.style.transform = `translate(${param.position.join(',')})`
      }
      param.dragOptions = {
        recover: false,
        ...param.dragOptions,
        trigger: "#head" + param.id,
        body: "#" + param.id,
      };

      if (param.id && this.isExist(param.id)) {
        if (!param.isUpdate) {
          //更新props 但是不会重新开始生命周期
          this.contents = this.contents.map(res => {
            if (res.id === param.id) {
              return param
            } else {
              return res
            }
          })
        } else {
          //强制更新生命周期
          this.closed(param)
          await this.$nextTick() //等待关闭后
          this.contents.push(param);
        }
      } else {
        this.contents.push(param);
      }
    },
    hide: function (id, flag = false) {
      this.titles[id] = flag;
      this.titles = Object.assign({}, this.titles);
    },
    mined: function (id, flag = true) {
      this.hide(id, flag);
    },
    maxed: function (id, flag) {
      if (flag) {
        this.contents.forEach(res => {
          if (res.id === id) {
            res.style = {
              ...res.style,
              width: document.body.clientWidth + 'px',
              height: document.body.clientHeight + 'px',
              transform: `translate(0, 0)`
            }
          }
        })
      } else {
        this.contents.forEach(res => {
          if (res.id === id) {
            let { width, height, transform, ...other } = res.style
            res.style = {
              ...other,
              transform: `translate(150px, 150px)`
            }
          }
        })
      }
    },
    closed: function (item) {
      this.hide(item.id);
      this.contents = this.contents.filter(res => res.id !== item.id);
      if (typeof item.closeCallback === "function") {
        item.closeCallback(item, this.contents)
      }
    },
    isExist: function (id) {
      let isExist = false;
      for (let item of this.contents) {
        if (item.id === id) {
          isExist = true;
          break;
        }
      }
      return isExist;
    },
    /**
     * 关闭全部浮层
     * @return void
     */
    closeAll: function () {
      this.contents.forEach(({ id }) => {
        this.hide(id);
      });

      this.contents = [];
    }
  }
};
</script>

<style lang="less" scoped>
.cs-window-container {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: all;
  z-index: 3010;
}

.cs-window-header {
  color: @light-char-color;
  cursor: pointer;
  pointer-events: all;

  .cs-window-header-item {
    display: flex;
    justify-content: space-between;
    width: 170px;
    height: 25px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background: @theme-color-9;
    margin: 5px 5px 2px 5px;
    padding: 0 10px;
    pointer-events: all;

    .text-hidden {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      line-height: 24px;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-icon-close {
      pointer-events: all;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
