// 配置信息

import { GUI } from 'dat.gui'
import store from '@/store'
import { viewer } from '@/components/cesium-map/main'

export const CESIUM_LOCAL_KEY = "cesium-params"
export let CesiumParams = {
  shouldAnimate:true,
  sun:false,
  moon:false,
  depthTestAgainstTerrain:false,
  undergroundMode:false,
  skyAtmosphere : false,
  showGroundAtmosphere : false,
  enableLighting : false,
  fog : false,
  fxaa: false,
  pixelRatio:window.devicePixelRatio,
  targetFrameRate: 60,
  useModel: true,
  maximumScreenSpaceError:3,
  maximumNumberOfLoadedTiles:1000,
  loadSiblings:true,
  preloadWhenHidden:true,
  maximumMemoryUsage:1024,
  requestRenderMode:false,
  logo:'华榕保安',
  fn:()=>{}
}

const CesiumParamsCopy = JSON.stringify(CesiumParams)


export const CesiumGui = new GUI({
  name:"渲染控制",
  autoPlace: false,
});
CesiumGui.width = 550;
// CesiumGui.hide()
const sysOptions = CesiumGui.addFolder( '系统选项' );

const mapOptions = CesiumGui.addFolder( '图像' );
const modeOptions = CesiumGui.addFolder( '模型设置(需刷新页面生效)' );

sysOptions.add( CesiumParams, 'fn').name("重置参数").onChange((value)=>{
  window.localStorage.setItem(CESIUM_LOCAL_KEY,CesiumParamsCopy)
  initCesiumParams()
  viewer.scene.skyAtmosphere.show = CesiumParams.skyAtmosphere;
  viewer.scene.globe.enableLighting = CesiumParams.enableLighting;
  viewer.scene.fog.enabled = CesiumParams.fog
  viewer.scene.postProcessStages.fxaa.enabled = CesiumParams.fxaa;
  viewer.targetFrameRate = CesiumParams.targetFrameRate;
  if(Cesium?.FeatureDetection.supportsImageRenderingPixelated()) {
      viewer.resolutionScale = CesiumParams.pixelRatio;
  }
  viewer?.scene?.requestRender()
})

sysOptions.add( CesiumParams, 'useModel').name("使用3D建模").onChange((value)=>{
  store.commit("scene/setUseModel",value)
  value ? modeOptions.show() : modeOptions.hide()
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

sysOptions.open()

mapOptions.add( CesiumParams, 'skyAtmosphere').name("天气，光照效果").onChange((value)=>{
  viewer.scene.skyAtmosphere.show = value;
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

mapOptions.add( CesiumParams, 'enableLighting').name("加强光照效果").onChange((value)=>{
  viewer.scene.globe.enableLighting = value
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

mapOptions.add( CesiumParams, 'fog').name("雾化效果").onChange((value)=>{
  viewer.scene.fog.enabled = value
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

mapOptions.add( CesiumParams, 'fxaa').name("抗锯齿").onChange((value)=>{
  viewer.scene.postProcessStages.fxaa.enabled = value;
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

mapOptions.add( CesiumParams, 'targetFrameRate').min(24).max(144).name("帧率").onChange((value)=>{
  viewer.targetFrameRate = value;
  viewer?.scene?.requestRender()
  saveCesiumParams()
})

if(Cesium?.FeatureDetection.supportsImageRenderingPixelated()) {
  mapOptions.add(CesiumParams, 'pixelRatio').min(1).max(window.devicePixelRatio).name("清晰度").onChange((value)=>{
    viewer.resolutionScale = value;
    viewer?.scene?.requestRender()
    saveCesiumParams()
  });
}
mapOptions.open();

modeOptions.add(CesiumParams,'requestRenderMode').name("显示渲染模式(提升性能/但可能出现闪烁)").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'loadSiblings').name("自动从中心开始超清化(关闭可提升性能)").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'preloadWhenHidden').name("后台预渲染(关闭可提升性能)").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'maximumScreenSpaceError').min(1).max(16).step(0.1).name("细节化程度、越小越清晰对应显卡要求越高").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'maximumNumberOfLoadedTiles').min(10).max(2000).step(10).name("最大加载瓦片数量(可提供更好的体验、对应显卡要求越高)").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'maximumMemoryUsage').min(256).max(2048).step(10.24).name("内存分配(建议50%显存)").onFinishChange(saveCesiumParams)
modeOptions.add(CesiumParams,'logo',['中国电信','华榕保安']).name("系统切换").onFinishChange(saveCesiumParams)
modeOptions.open();


function getScreenFps(targetCount = 50){
  // 先做一下兼容性处理
  const nextFrame = ([
    window.requestAnimationFrame,
    window.webkitRequestAnimationFrame,
    window.mozRequestAnimationFrame
  ]).find(fn => fn)
  if (!nextFrame) {
    console.error('requestAnimationFrame is not supported!')
    return
  }
  // 判断参数是否合规
  if (targetCount < 1) throw new Error('targetCount cannot be less than 1.')
  const beginDate = Date.now()
  let count = 0
  return new Promise(resolve => {
    (function log() {
      nextFrame(() => {
        if (++count >= targetCount) {
          const diffDate = Date.now() - beginDate
          const fps = (count / diffDate) * 1000
          return resolve(Math.floor(fps / 3) * 3)
        }
        log()
      })
    })()
  })
}

// 初始化参数
export function initCesiumParams(){
  let params = window.localStorage.getItem(CESIUM_LOCAL_KEY)
  if(params){
    try{
      params = JSON.parse(params)
    }catch (e) {
      params = {}
    }
  }
  CesiumParams = Object.assign(CesiumParams,params)
  store.commit("scene/setUseModel",CesiumParams.useModel)
  CesiumParams.useModel ? modeOptions.show() : modeOptions.hide()
  mapOptions.updateDisplay()
  modeOptions.updateDisplay()
  sysOptions.updateDisplay()
}

// 保存参数
export function saveCesiumParams(){
  window.localStorage.setItem(CESIUM_LOCAL_KEY,JSON.stringify(CesiumParams))
}
