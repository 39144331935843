import config from '@/config'
import AMapLoader from '@amap/amap-jsapi-loader'


export default async function loadAmap (){
  window.globalAMap = null
  window._AMapSecurityConfig = { securityJsCode: config.AMapCode }
  window.AMap = await AMapLoader.load({
    key: config.AMapKey,             // 申请好的Web端开发者Key，首次调用 load 时必填
    version: '2.0',      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [
      'AMap.ElasticMarker',
      'AMap.PolygonEditor',
      'AMap.Walking',
      'AMap.MouseTool',
      'AMap.Walking'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    Loca: {
      version: '2.0' // Loca 版本，缺省 1.3.2
    },
    // 是否加载 AMapUI，缺省不加载
    AMapUI: {
      version:"1.1" // AMapUI 缺省 1.1
    }
  })
  return true
}
