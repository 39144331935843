import { on } from '@/libs/common'

const hasPermission = key => {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

const  permissionDirective = {
  inserted:((el,binding)=>{
    if(!hasPermission(binding.value)){
      el.parentNode.removeChild(el);
    }
  })
}

export default  permissionDirective;
