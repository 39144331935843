import {getDict} from "@/services/common";
import {dictTranslate} from "@/libs/common";


const dictMixins = {
    data(){
        return {
            dictData:{}
        }
    },
    methods:{
        // 获取字典对象
        getDict(...dictTypes){
            let items = []
            dictTypes.forEach(res=>{
                items.push(getDict(res,'object').then(data=>{
                    this.dictData = {
                        ...this.dictData,
                        [res]:data
                    }
                }))
            })
            return Promise.all(items)
        },
        // 获取字典数组
        getDictArray(...dictTypes){
            let items = []
            dictTypes.forEach(res=>{
                items.push(getDict(res).then(data=>{
                    this.dictData = {
                        ...this.dictData,
                        [res]:data
                    }
                }))
            })
            return Promise.all(items)
        }
    }
}


export default  dictMixins
